import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ArrayUtils } from '@ecommerce/utils/array.extensions';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { UserApiControllerClient, UserVm, UserRole } from '@ecommerce/ui-services/client-api';


const CACHE_SIZE = 20;

@Injectable( { providedIn: 'root' } )
export class UserCachedClientApiService {
  constructor (
    private readonly _userApiService: UserApiControllerClient,
    @Inject( PLATFORM_ID ) private platformId: string
  ) { }
  private pageObservablesCache$: {
    [ functionArgs: string ]: Observable<any>;
  } = {};

  findByCached ( slug: string ): Observable<UserVm> {
    if ( isPlatformServer( this.platformId ) )
      return this._userApiService.findById( slug );
    const prop = `finduserById-${JSON.stringify(
      ArrayUtils.argumentsToArgsArray( arguments )
    )}`;
    if ( !this.pageObservablesCache$[ prop ] ) {
      this.pageObservablesCache$[ prop ] = this._userApiService
        .findById( slug )
        .pipe( shareReplay( CACHE_SIZE ) );
    }

    return this.pageObservablesCache$[ prop ];
  }
  
  searchUsers({searchText, roles}: {searchText: string, roles?: UserRole[]}) {
    if ( isPlatformServer( this.platformId ) )
      return this._userApiService.searchUsers(roles, searchText);
    const prop = `searchUser-${JSON.stringify(
      ArrayUtils.argumentsToArgsArray( arguments )
    )}`;
    if ( !this.pageObservablesCache$[ prop ] ) {
      this.pageObservablesCache$[prop] = this._userApiService
        .searchUsers(roles, searchText)
        .pipe(shareReplay(CACHE_SIZE));
    }
  
    return this.pageObservablesCache$[ prop ];
  }
}
