import {Component, ChangeDetectionStrategy, ViewChild, NgModule, OnInit} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {MatSlider, MatSliderModule} from '@angular/material/slider';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'formly-field-mat-slider',
  styles: [
    '.formly-slider-wrapper {margin-bottom: 1.5rem;}',
    '.mat-slider-horizontal{height: 38px;}',
  ],
  template: `
    <div class="formly-slider-wrapper">
      <label for="id">
        {{ to.label }}
      </label>
      <mat-slider
        [id]="id"
        [required]="to.required"
        [style.width]="'100%'"
        [formControl]="fc"
        [tabIndex]="to.tabindex"
        [color]="to.color"
        [displayWith]="to.displayWith"
        [invert]="to.invert"
        [max]="to.max"
        [min]="to.min"
        [step]="to.step"
        [thumbLabel]="to.thumbLabel"
        [tickInterval]="to.tickInterval"
        [valueText]="to.valueText"
        [vertical]="to.vertical"
        (input)="to.input && to.input(field, $event)"
        (change)="to.change && to.change(field, {form:form, field: field})"
      >
      </mat-slider>
      <label for="id" class="font-size-12 grey-600-fg">
        {{ to.description }}
      </label>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldSlider extends FieldType implements OnInit {
  @ViewChild(MatSlider, {static: true}) slider!: MatSlider;
  fc: any;
  ngOnInit(): void {
    this.fc = this.formControl;
  }
}

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatSliderModule],
  exports: [],
  declarations: [FormlyFieldSlider],
  providers: [],
})
export class FormlyFielBoxRadioModule {}