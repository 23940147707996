import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {
  PageApiControllerClient,
  PageVm,
  BlogCategoryBasicVm,
} from '../client-api.service';

import {Observable} from 'rxjs';
import {shareReplay, map} from 'rxjs/operators';
import {ArrayUtils} from '@ecommerce/utils/array.extensions';
import {isPlatformServer} from '@angular/common';

const CACHE_SIZE = 1;

@Injectable({providedIn: 'root'})
export class PageCachedClientApiService {
  constructor(
    private readonly _pageApiService: PageApiControllerClient,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}
  private pageObservablesCache$: {
    [functionArgs: string]: Observable<any>;
  } = {};

  findBySlugCached(slug: string): Observable<PageVm> {
    if (isPlatformServer(this.platformId))
      return this._pageApiService.findBySlug(slug);
    const prop = `findBySlug-${JSON.stringify(
      ArrayUtils.argumentsToArgsArray(arguments)
    )}`;
    if (!this.pageObservablesCache$[prop]) {
      this.pageObservablesCache$[prop] = this._pageApiService
        .findBySlug(slug)
        .pipe(shareReplay(CACHE_SIZE));
    }

    return this.pageObservablesCache$[prop];
  }
  findAllCached(
    filter: any,
    skip?: number,
    limit?: number,
    sort?: any
  ): Observable<PageVm[]> {
    if (isPlatformServer(this.platformId))
      return this._pageApiService.findAll(skip, limit, sort, filter);
    const prop = `findAll-${JSON.stringify(
      ArrayUtils.argumentsToArgsArray(arguments)
    )}`;
    if (!this.pageObservablesCache$[prop]) {
      this.pageObservablesCache$[prop] = this._pageApiService
        .findAll(skip, limit, sort, filter)
        .pipe(shareReplay(CACHE_SIZE));
    }

    return this.pageObservablesCache$[prop];
  }

  getPageCountCached(filter: any): Observable<number> {
    if (isPlatformServer(this.platformId))
      return this._pageApiService.getPageCount(filter);
    const prop = `getPageCount-${JSON.stringify(
      ArrayUtils.argumentsToArgsArray(arguments)
    )}`;
    if (!this.pageObservablesCache$[prop]) {
      this.pageObservablesCache$[prop] = this._pageApiService
        .getPageCount(filter)
        .pipe(shareReplay(CACHE_SIZE));
    }

    return this.pageObservablesCache$[prop];
  }

  searchCategories(searchString: string): Observable<BlogCategoryBasicVm[]> {
    if (isPlatformServer(this.platformId))
      return this._pageApiService.searchCategories(searchString);
    const prop = `searchCategories-${JSON.stringify(
      ArrayUtils.argumentsToArgsArray(arguments)
    )}`;
    if (!this.pageObservablesCache$[prop]) {
      this.pageObservablesCache$[prop] = this._pageApiService
        .searchCategories(searchString)
        .pipe(shareReplay(CACHE_SIZE));
    }

    return this.pageObservablesCache$[prop];
  }
}
