import {
  Component,
  ViewChild,
  OnInit,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  NgModule,
} from '@angular/core';
import {FieldType} from '@ngx-formly/material/form-field';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';

import {Observable, Subject} from 'rxjs';
import {startWith, map, takeUntil, tap, switchMap} from 'rxjs/operators';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { difference } from "lodash-es";

@Component({
  template: `
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let item of formControl.value; let i = index"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(i)"
      >
        {{ item }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        matInput
        [formControl]="itemControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
        (blur)="onBlur()"
      />
    </mat-chip-list>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option *ngFor="let item of filter$ | async" [value]="item">
        {{ item }}
      </mat-option>
    </mat-autocomplete>
    <!-- </mat-form-field> -->
  `,
  styles: [
    `
      .chip-list {
        width: 100%;
      }
      .mat-standard-chip .mat-chip-remove {
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 0;
        background: none;
      }
      .mat-standard-chip .mat-chip-remove.mat-icon,
      .mat-standard-chip .mat-chip-remove .mat-icon {
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
      .mat-standard-chip.mat-chip-with-trailing-icon {
        padding: 0px 10px;
        min-height: 18px;
        font-weight: 600;
        font-size: 12px;
      }
    `,
  ],
})
export class FormlyChipsComponent
  extends FieldType
  implements OnInit, OnDestroy, AfterViewInit
{
  onDestroy$ = new Subject<void>();

  itemControl = new FormControl();
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  filter$: Observable<any[]>;

  ngOnInit() {
    super.ngOnInit();

    // TODO: Fix the logic of difference of filter and formControl.value
    this.filter$ = this.itemControl.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      startWith(null),
      map((item: any | null) => (item ? this._filter(item) : this._filter(''))),      
    );
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // temporary fix for https://github.com/angular/material2/issues/6728
    (<any>this.matAutocomplete)._formField = this.formField;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // get empty() {
  //   return this.formControl?.value?.length !> 0 ;
  // }

  public add(event: MatChipInputEvent): void {
    // Add item only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add item
      if ((value || '').trim()) {
        this.formControl.setValue([...this.formControl.value, value.trim()]);
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.itemControl.setValue(null);
    }
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    this.formControl.setValue([
      ...this.formControl.value,
      event.option.viewValue,
    ]);

    this.itemControl.setValue(null);
  }

  public remove(i: number): void {
    const value = this.formControl.value;

    this.formControl.setValue([
      ...value.slice(0, i),
      ...value.slice(i + 1, value.length),
    ]);
    this.formControl.markAsTouched();
  }

  private _filter(value: any): any[] {
    if (!this.to.filter) return [];
    const notAddedFields = difference(this.to.filter, this.formControl.value);
    if (!value) return notAddedFields.slice();

    const filterValue = value.toLowerCase();

    return notAddedFields.filter(
      (item: string) => item.toLowerCase().indexOf(filterValue) === 0
    );
  }

  onBlur() {
    this.formControl.markAsTouched();
    this.field.focus = false;
  }
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatInputModule,
    MatIconModule,
    
    FlexLayoutModule,
  ],
  exports: [FormlyChipsComponent, MatAutocompleteModule],
  declarations: [FormlyChipsComponent],
  providers: [],
})

export class FormlyFieldCustomAutocompleteModule {}
