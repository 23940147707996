import { NgModule } from '@angular/core';
import { SafeHtml } from './safeHtml';
import { PlatformPipe } from './platform.pipe';
import { ValuesPipe } from './values.pipe';
import { SafeUrl } from './safeUrl';

@NgModule( {
  imports: [],
  exports: [ SafeUrl, SafeHtml, PlatformPipe, ValuesPipe ],
  declarations: [ SafeUrl, SafeHtml, PlatformPipe, ValuesPipe ],
  providers: [],
} )
export class PipesModule { }
