export const locale = {
  lang: 'en',
  data: {
    NAV: {
      APPLICATIONS: 'Applications',
      AUTH: 'Login/Register',
      HOME: {
        TITLE: 'Home',
        BADGE: '25',
      },
      ABOUT: {
        TITLE: 'About',
        BADGE: '80',
      },
      CONTACT: {
        TITLE: 'Contact',
        BADGE: '30',
      },
      ARTICLES: {
        TITLE: 'Resources',
        BADGE: '30',
      },
      LOGIN: {
        TITLE: 'Login',
        BADGE: 'New',
      },
      
    },
  },
};
