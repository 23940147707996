import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditImageModalComponent } from './edit-image-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { InlineEditorModule } from '@ecommerce/ui-components/modules/dynamic-page/components/inline-editor/inline-editor.module';
import { QuillModule } from '../../quill-editor/quill.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ImageUploaderModule } from '../../image-uploader/image-uploader.module';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyModule } from '@ngx-formly/core';
import { formlyDefaultConfig } from '@ecommerce/ui-components/modules/formly-fields/formly.default.config';



@NgModule( {
  declarations: [ EditImageModalComponent ],
  exports: [ EditImageModalComponent ],
  entryComponents: [ EditImageModalComponent ],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    InlineEditorModule,
    QuillModule,
    ImageUploaderModule,
    FormlyModule.forChild( formlyDefaultConfig ),
    FormlyMaterialModule,
  ],
} )
export class EditImageModalModule { }
