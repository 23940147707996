import { isPlatformServer } from '@angular/common';
import {
  AfterContentChecked,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[createOnScroll]',
})
export class CreateOnScrollDirective implements OnInit, OnDestroy {
  isCreated: boolean;
  private observer: IntersectionObserver;
  @Input() createOnScroll: {enable?: boolean; threshold?: number} = {};

  /**
   * Constructor
   *
   * @param {ElementRef} _elementRef
   * @param {TemplateRef<any>} _templateRef
   * @param {ViewContainerRef} _viewContainerRef
   */
  constructor(
    private _elementRef: ElementRef,
    private _templateRef: TemplateRef<any>,
    private _viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private _platformId: string
  ) {
    // Set the defaults
    this.isCreated = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * After content checked
   */
  ngOnInit(): void {
    if (isPlatformServer(this._platformId)) {
      return;
    }

    this.observer = new IntersectionObserver(
      (entry) => {
        const intersectingElement = entry[0];
        if (intersectingElement && intersectingElement.isIntersecting) {
          if (
            document.body.contains(this._elementRef.nativeElement) &&
            !this.isCreated
          ) {
            setTimeout(() => {
              this._viewContainerRef.createEmbeddedView(this._templateRef);
            }, 300);
            this.observer.unobserve(
              this._templateRef.elementRef.nativeElement.parentElement
            );
            this.observer.disconnect();
            this.isCreated = true;
          } else if (
            this.isCreated &&
            !document.body.contains(this._elementRef.nativeElement)
          ) {
            this._viewContainerRef.clear();
            this.isCreated = false;
          }
          this.observer.unobserve(
            this._templateRef.elementRef.nativeElement.parentElement
          );
          this.observer.disconnect();
        }
      },
      {
        threshold: this.createOnScroll.threshold || 0.5,
      }
    );

    if (!this.createOnScroll.enable) {
      if (
        document.body.contains(this._elementRef.nativeElement) &&
        !this.isCreated
      ) {
        setTimeout(() => {
          this._viewContainerRef.createEmbeddedView(this._templateRef);
        }, 300);
      }
    } else {
      this.observer.observe(
        this._templateRef.elementRef.nativeElement.parentElement
      );
    }
  }
  ngOnDestroy() {
    this.observer && this.observer.disconnect();
  }
}
