import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DexImageComponent } from './dex-image.component';
import {
  LazyLoadImageModule,
  LAZYLOAD_IMAGE_HOOKS,
  ScrollHooks,
} from 'ng-lazyload-image';



@NgModule({
  declarations: [
    DexImageComponent
  ],
  imports: [CommonModule, LazyLoadImageModule],
  exports: [DexImageComponent],
  providers: [
    {provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks},
  ],
})
export class DexImageModule {}
