import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ParallaxDirective } from './parallax.directive';
import { WowDirective } from './wow.directive';
import { InfiniteScrollDirective } from './infinte-scroll.directive';
import { RouteTransformerDirective } from './route-transformer/route-transformer.directive';
import { FixedNavbarDirective } from './scroll-directives.directive';
import { CounterDirective } from './counter/counter.directive';
import { CreateOnScrollDirective } from './create-on-scroll/create-on-scroll.directive';
import { ReadMoreDirective } from './read-more/read-more.directive';
import { LetDirective } from './ng-let.directive';

const imports = [
  ParallaxDirective,
  WowDirective,
  InfiniteScrollDirective,
  RouteTransformerDirective,
  FixedNavbarDirective,
  CounterDirective,
  CreateOnScrollDirective,
  ReadMoreDirective,
  LetDirective
];

@NgModule({
  imports: [CommonModule, FlexLayoutModule],
  declarations: imports,
  exports: imports,
})
export class UiDirectivesModule {}
