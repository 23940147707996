import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable( {
  providedIn: 'root'
} )
export class LoadingService {
  loadingObserver: BehaviorSubject<boolean> = new BehaviorSubject( false );
  constructor () { }

  public get loading () {
    return this.loadingObserver.value;
  }
  public set loading ( val ) {
    this.loadingObserver.next( val );
  }
}
