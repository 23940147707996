import {
  Directive,
  ElementRef,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID,
  OnDestroy,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Directive({
  selector: '[infiniteScroll]',
})
export class InfiniteScrollDirective implements OnInit, OnDestroy {
  // Enable this tag once reached the Limit
  @Input() scrollLimitReached = false;

  // Enable this if you want to use the infiniteScroll Directive
  @Input() enable = false;

  // Precentage of item to be on display from 0.1 to 1
  @Input() threshold = 0.1;

  // Call back to load more data
  @Output() loadMore = new EventEmitter();

  private observer: any;

  constructor(
    private el: ElementRef,
    @Inject(PLATFORM_ID) private _platformId: string
  ) {}

  ngOnInit() {
    if (this.enable) {
      if (!isPlatformServer(this._platformId)) this.render();
    }
  }
  render() {
    this.observer = new IntersectionObserver(
      (entry) => {
        const intersectingElement = entry[0];
        if (intersectingElement && intersectingElement.isIntersecting) {
          if (!this.scrollLimitReached) {
            this.scrolling();
          } else {
            this.observer.unobserve(this.el.nativeElement);
          }
        }
      },
      {
        threshold: this.threshold,
      }
    );
    this.observer.observe(this.el.nativeElement);
  }
  ngOnDestroy() {
    if (!isPlatformServer(this._platformId)) this.observer.disconnect();
  }
  scrolling() {
    if (this.loadMore.observers.length > 0) {
      this.loadMore.emit('true');
    }
  }
}
