import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  Inject,
  PLATFORM_ID,
  OnDestroy,
  Input,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[dirCounter]',
})
export class CounterDirective implements OnInit {
  intervalId: any;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private _platformId: string
  ) {}

  private observer: IntersectionObserver;
  @Input() threshold = 0.1;
  @Input() value = 100;
  @Input() delay = 3000;

  ngOnInit() {
    if (isPlatformBrowser(this._platformId)) this.render();
  }

  counter() {
    let i = 0;
    if (this.intervalId) return;
    const runner = () => {
      this.el.nativeElement.innerHTML = `${i.toFixed()}`;
      if (i >= this.value) {
        this.el.nativeElement.innerHTML = `${this.value}`;
        this.clearAll();
      } else i = i + this.value / 100;
    };
    // This block will be executed value times.
    this.intervalId = setInterval(runner, this.delay / 300);
  } // End

  clearAll() {
    clearInterval(this.intervalId);
    this.observer.unobserve(this.el.nativeElement);
    this.observer.disconnect();
  }
  render() {
    this.observer = new IntersectionObserver(
      (entry) => {
        const intersectingElement = entry[0];
        if (intersectingElement && intersectingElement.isIntersecting) {
          this.counter();
        }
      },
      {
        threshold: this.threshold,
      }
    );
    this.observer.observe(this.el.nativeElement);
  }
}
