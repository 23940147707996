import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivate,
  CanActivateChild,
} from '@angular/router';
import {Observable, of, throwError, EMPTY} from 'rxjs';
import {ReturnUrlService} from '../returnUrl/returnUrl.service';
import {Injectable} from '@angular/core';
import {
  tap,
  switchMap,
  map,
  flatMap,
  throwIfEmpty,
  catchError,
  take,
} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';
import {Roles, UserVm} from '../client-api';
import { environment } from '@ecommerce/environments/client/environment';

export enum AuthGuardMessages {
  NOT_AUTHORIZED = 'Not Authorized',
  ACCOUNT_DEACTIVE = 'User Account Deactive',
  ADMIN_REQUIRED = 'Admin Required',
  ENG_REQUIRED = 'Engineer Required',
  EMAIL_UNVERIFIED = 'Email Verification Required',
  CSR_REQUIRED = 'CSR Required',
  BLOGGER_REQUIRED = 'Blogger Required',
  CM_REQUIRED = 'Content Manager Required',
}

interface CanAuthorize {
  authorize(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>;
}

class DextBaseAuthGuard {
  constructor(
    private _router: Router,
    private _returnUrl: ReturnUrlService,
    private _authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authorize(route, state);
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authorize(route, state);
  }
  authorize(route, state) {
    return of(false);
  }
  setReturnUrl(state: RouterStateSnapshot) {
    this._returnUrl.url = state.url;
  }
  handleError(state, message = AuthGuardMessages.NOT_AUTHORIZED) {
    this.setReturnUrl(state);
    return throwError(new Error(message));
  }
  authorizeWithService(
    state,
    mappingFunction: (roles: string[]) => boolean,
    message: AuthGuardMessages
  ) {
    return this._authService.isLoggedIn
    .pipe(
      switchMap((yes) => (yes ? of(true) : this.handleError(state, message))),
      switchMap((loggedIn) =>
        loggedIn && !!this._authService.user.value
          ? this._authService.rolesChanges.pipe(take(1))
          : this._authService._getCurrentUserAndRolesRx()
          .pipe(
              take(1),
              map((user: UserVm) => {
                return user && user.roles ? user.roles : [];
              })
            )
      ),
      switchMap((roles) =>
        roles === null ? this.handleError(state, message) : of(roles)
      ),
      // Master Checks for Users with Verfied Email Addresses Can only Login
      switchMap(roles => 
        environment.onlyVerifiedLogins && 
        !this._authService.isVerified.value &&
        this._authService.checkAnyOfRoles([Roles.Engineer, Roles.User])
        ? this.handleError(state, AuthGuardMessages.EMAIL_UNVERIFIED)
        : of(roles)
      ),
      map((roles: any) => mappingFunction(roles)),
      switchMap((__) => (__ ? of(true) : this.handleError(state, message)))
    );
  }
}

@Injectable()
export class OnlyLoggedInUsersGuard extends DextBaseAuthGuard
  implements CanActivate, CanActivateChild, CanAuthorize {
  constructor(
    public authService: AuthService,
    private router: Router,
    private returnUrl: ReturnUrlService
  ) {
    super(router, returnUrl, authService);
  }

  authorize(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isLoggedIn.pipe(
      tap((__) => !__ && this.setReturnUrl(state)),
      switchMap((loggedIn) =>
        loggedIn
          ? of(true)
          : this.handleError(state, AuthGuardMessages.NOT_AUTHORIZED)
      )
    );
  }
}
@Injectable()
export class OnlyActiveUserAccountGuard extends DextBaseAuthGuard
  implements CanActivate, CanActivateChild, CanAuthorize {
  constructor(
    public authService: AuthService,
    private router: Router,
    private returnUrl: ReturnUrlService
  ) {
    super(router, returnUrl, authService);
  }

  authorize(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isActive.pipe(
      tap((__) => !__ && this.setReturnUrl(state)),
      switchMap((isActive) =>
        isActive
          ? of(true)
          : this.handleError(state, AuthGuardMessages.ACCOUNT_DEACTIVE)
      )
    );
  }
}

@Injectable()
export class OnlySPUsersGuard extends DextBaseAuthGuard
  implements CanActivate, CanActivateChild, CanAuthorize {
  constructor(
    public authService: AuthService,
    private router: Router,
    private returnUrl: ReturnUrlService
  ) {
    super(router, returnUrl, authService);
  }
  authorize(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authorizeWithService(
      state,
      (roles: string[]) =>
        roles.includes(Roles.Engineer) ||
        roles.includes(Roles.Admin) ||
        roles.includes(Roles.Csr),
      AuthGuardMessages.ENG_REQUIRED
    );
  }
}

@Injectable()
export class OnlyCSRUsersGuard extends DextBaseAuthGuard
  implements CanActivate, CanActivateChild, CanAuthorize {
  constructor(
    public authService: AuthService,
    private router: Router,
    private returnUrl: ReturnUrlService
  ) {
    super(router, returnUrl, authService);
  }
  authorize(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authorizeWithService(
      state,
      (roles: string[]) =>
        roles.includes(Roles.Csr) || roles.includes(Roles.Admin),
      AuthGuardMessages.CSR_REQUIRED
    );
  }
}
@Injectable()
export class OnlyContentUsersGuard extends DextBaseAuthGuard
  implements CanActivate, CanActivateChild, CanAuthorize {
  constructor(
    public authService: AuthService,
    private router: Router,
    private returnUrl: ReturnUrlService
  ) {
    super(router, returnUrl, authService);
  }
  authorize(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authorizeWithService(
      state,
      (roles: string[]) =>
        roles.includes(Roles.Blogger) ||
        roles.includes(Roles.ContentManager) ||
        roles.includes(Roles.Csr) ||
        roles.includes(Roles.Admin),
      AuthGuardMessages.CSR_REQUIRED
    );
  }
}

@Injectable()
export class OnlyAdminUserGuard extends DextBaseAuthGuard
  implements CanActivate, CanActivateChild, CanAuthorize {
  constructor(
    public authService: AuthService,
    private router: Router,
    private returnUrl: ReturnUrlService
  ) {
    super(router, returnUrl, authService);
  }
  authorize(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authorizeWithService(
      state,
      (roles: string[]) => roles.includes(Roles.Admin),
      AuthGuardMessages.ADMIN_REQUIRED
    );
  }
}
