import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

// import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse-cs/components';
import { FuseCsSharedModule } from '@fuse-cs/shared.module';

import { ToolbarComponent } from '../../components/toolbar/toolbar.component';
import { DexImageModule } from "@ecommerce/ui-components/components/dex-image/dex-image.module";
@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,

    FuseCsSharedModule,

    DexImageModule,
    // FuseSearchBarModule,
    // FuseShortcutsModule,
  ],
  exports: [ToolbarComponent],
})
export class ToolbarModule {}
