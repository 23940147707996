import { Injectable, InjectionToken, Inject } from '@angular/core';
import { ConfigOptions } from '../types/types.config';
import { config } from 'rxjs';

export const COMPONENT_CONFIG = new InjectionToken<ConfigProviderService>(
  'COMPONENT_CONFIG'
);

@Injectable({
  providedIn: 'root',
})
export class ConfigProviderService {
  private readonly components: { [key: string]: any } = {};
  public registerComponents(configOptions: ConfigOptions) {
    if (configOptions && configOptions.types) {
      configOptions.types.forEach((c) => {
        this.components[c.type] = c.component;
      });
    }
  }
  public async getComponent(type): Promise<any> /** Component */ {
    const componentPromise = this.components[type];
    try {
      if (typeof componentPromise !== 'function') {
        console.error('Component is not a promise for tag: ', type);
      }
      const component = await componentPromise();
      if (!component) {
        throw Error(`No Component Registered for Type: ${type}`);
      }
      return component;
    } catch (e) {
      throw Error(`No Component Registered for Type: ${type}`);
    }
  }
}
