import {
  Component,
  OnInit,
  NgModule,
  Compiler,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FieldType } from '@ngx-formly/core';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-field-custom-autocomplete',
  templateUrl: 'formlyfield.custom-autocomplete.html',
  styleUrls: ['formlyfield.custom-autocomplete.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyFieldCustomAutocomplete extends FieldType implements OnInit{
  fc;
  opts;
  ngOnInit(): void {
    this.fc = this.formControl;
    this.opts = this.to.options;
  }
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    NgSelectModule,
    FlexLayoutModule
  ],
  exports: [FormlyFieldCustomAutocomplete, MatAutocompleteModule],
  declarations: [FormlyFieldCustomAutocomplete],
  providers: [],
})
export class FormlyFieldCustomAutocompleteModule {}
