import {
  Component,
  OnInit,
  NgModule,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule, FormControl} from '@angular/forms';
import {ArrayUtils} from '@ecommerce/utils/array.extensions';
import {MatListModule} from '@angular/material/list';
import {Subject} from 'rxjs';
import {takeUntil, filter, take, tap} from 'rxjs/operators';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-field-array-toggle',
  templateUrl: 'array-toggle-switch.html',
  styleUrls: ['array-toggle-switch.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyFieldArrayToggleComponent extends FieldType
  implements OnInit {
  value: Array<string> = [];  
  opts;
  ngOnInit() {
    this.value = [...(this.formControl.value || [])];
    this.opts = this.to.options;
  }
  onChange(change: MatSlideToggleChange, name: string) {
    const _value = this.value;
    if (change.checked) {
      ArrayUtils.push(_value, name);
    } else {
      _value.splice(
        _value.findIndex((n) => n === name),
        1
      );
    }
    this.formControl.setValue(_value);
    if(this.to.change) {
      this.to.change(this.field, _value);
    }
  }
  getChecked(name) {
    return this.value.findIndex((n) => n === name) !== -1;
  }
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatListModule,
  ],
  exports: [],
  declarations: [FormlyFieldArrayToggleComponent],
  providers: [],
})
export class FormlyFielArrayToggleModule {}
