import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@ecommerce/ui-services/fuse/config.service';

// TODO: Get Navigation through Service/Input

@Component( {
  selector: 'horizontal-layout-1',
  templateUrl: './layout-1.component.html',
  styleUrls: [ './layout-1.component.scss' ],
  encapsulation: ViewEncapsulation.None,
} )
export class HorizontalLayout1Component implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor ( private _fuseConfigService: FuseConfigService ) {
    // Set the defaults
    this.navigation = [];

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit (): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe( takeUntil( this._unsubscribeAll ) )
      .subscribe( ( config ) => {
        this.fuseConfig = config;
      } );
  }

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
