import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  ViewEncapsulation,
  OnChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadImageComponent } from './upload-image-dialog';
import {
  FileParameter,
  ImageControllerClient,
  ImagesVm,
} from '@ecommerce/ui-services/client-api';
import { Subject, EMPTY, EmptyError, Observable } from 'rxjs';
import {
  takeUntil,
  switchMap,
  tap,
  map,
  filter,
  take,
  catchError,
} from 'rxjs/operators';
import { EditorConfigService } from '@ecommerce/ui-components/modules/dynamic-page/providers/editor-config.service';

export type FileEventTarget = EventTarget & { files: FileList };

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageUploaderComponent implements OnInit, OnDestroy, OnChanges {
  // Event emitted once the file is uploaded and saved in DB
  @Output() fileSaved: EventEmitter<any> = new EventEmitter();

  // Aspect Ratio
  @Input() aspectRatio;
  @Input() maintainAspectRatio: boolean = true;

  // Limit of Image sizes
  @Input() resizeToWidth;
  @Input() resizeToHeight;

  // Cropper Size Limits, it will not crop below that size.
  // Important if you dont want to scale up the image.
  @Input() cropperMinHeight;
  @Input() cropperMinWidth;

  // Flag to set if you want to crop or not
  @Input() cropTheImage = true;
  // Image file to replace in DB
  @Input() image: {
    id: string;
    url: string;
  };
  @Input() imagePositionX: number;
  @Input() imagePositionY: number;
  @Input() contain: 'cover' | 'contain';
  @Input() parallex = false;
  @Input() contentPosition;
  // Position of the icon
  @Input() position = 'center-center';
  // Shows an overlay
  @Input() showOverlay = false;
  @Input() editable;
  // Event thrown by Input type=file control
  imageChangedEvent: any = '';
  // CroppedImage recieved from the cropper
  croppedImage: any;
  // Flag to check if Image is uploading to the DB
  uploading: boolean;
  // Flag to check if the component is spawned on cms.
  editible: boolean;
  private unSubAll: Subject<any> = new Subject();

  constructor(
    private readonly _dialog: MatDialog,
    private readonly _imageService: ImageControllerClient,
    private readonly _editorService: EditorConfigService
  ) {
    this.editible = this._editorService.editible;
  }

  ngOnInit() {
    this.aspectRatio = this.cropperMinHeight ?
      this.aspectRatio || this.cropperMinWidth / this.cropperMinHeight : undefined;
  }
  ngOnChanges(c) {
    if (c.editable && c.editable.currentValue) {
      this.editible = c.editable;
    }
  }
  selectFile(event: Event, inputControl: HTMLInputElement) {
    event.stopPropagation();
    inputControl.click();
  }

  /**
   * Event fired by input[type:file]
   * Opens a Cropper Dialog if [this.cropTheImage] flag is set to true
   * Otherwise sends the file directly to prepareFormData
   * @author Aun
   * @date 2020-05-17
   * @param {*} event
   * @memberof ImageUploaderComponent
   */
  fileChangeEvent(event: Event): void {
    event.stopPropagation();
    if (event) {
      this.imageChangedEvent = event;
      if (this.cropTheImage) {
        this.openDialog(this.imageChangedEvent)
          .pipe(takeUntil(this.unSubAll))
          .subscribe();
      } else {
        this.saveImage((event.target as FileEventTarget).files[0])
          .pipe(takeUntil(this.unSubAll))
          .subscribe();
      }
    }
  }

  /**
   * Saves the file in DB and even removes the old file.
   * @author Arslan
   * @date 2020-05-17
   * @memberof ImageUploaderComponent
   */
  private saveImage(fileToUpload?: File): Observable<ImagesVm> {
    this.uploading = true;
    const formDataToUpload = this.prepareFormData(fileToUpload);
    
    return this._imageService.uploadImageLarge(formDataToUpload as any).pipe(
      takeUntil(this.unSubAll),
      map((res) => {
        const oldId = this.image && this.image.id;
        this.fileSaved.emit({
          id: res.id,
          url: res.imageObject.original.location,
        });
        return oldId;
      }),
      tap((_) => (this.uploading = false)),
      filter((id) => !!id),
      switchMap((id) => this._imageService.deleteImage(id)),
      catchError((err) => {
        console.log(err);
        this.uploading = false;
        return EmptyError;
      })
    );
  }

  /**
   * Prepares the FormDate object to send to Backend
   * @author Arslan
   * @date 2020-05-17
   * @private
   * @returns
   * @memberof ImageUploaderComponent
   */
  private prepareFormData(fileToUpload?: File) {
    if (this.cropTheImage) {
      const blob = this.dataURItoBlob(this.croppedImage);
      fileToUpload = new File(
        [blob],
        this.imageChangedEvent.target.files[0].name,
        {
          type: this.imageChangedEvent.target.files[0].type,
          lastModified: new Date().getTime(),
        }
      );
    }

    // const formDataToUpload = new FormData();
    // formDataToUpload.append('file', 
    return this.updateFileName(fileToUpload)
    // );
    // return formDataToUpload;
  }

  /**
   * Updates the filename while adding a random prefix in the begining
   * Required to save the file on S3 as you might want to upload the different cropped
   * @date 2020-05-17
   * @param {File} file
   * @returns {File}
   * @memberof ImageUploaderComponent
   */
  private updateFileName(file: File): FileParameter {
    const blob = file.slice(0, file.size, 'image/png');
    console.log(blob);
    return {
      data: blob,
      fileName: `${Math.round(Math.random() * 1000000)}-${file.name}`,
    };
    // new File(
    //   [blob],
    //   `${Math.round(Math.random() * 1000000)}-${file.name}`,
    //   {type: 'image/png'}
    // );
  }

  /**
   * Opens the Cropper Dialog for cropping
   * @author Aun
   * @date 2020-05-17
   * @private
   * @param {*} image
   * @memberof ImageUploaderComponent
   */
  private openDialog(image): Observable<ImagesVm> {
    const dialogRef = this._dialog.open(UploadImageComponent, {
      width: '800px',
      data: {
        image,
        cropperMinHeight: this.cropperMinHeight,
        cropperMinWidth: this.cropperMinWidth,
        resizeToWidth: this.resizeToWidth,
        resizeToHeight: this.resizeToHeight,
        aspectRatio: this.aspectRatio,
        maintainAspectRatio: this.maintainAspectRatio,
        format: image.target.files[0].type,
      },
    });
    return dialogRef.afterClosed().pipe(
      take(1),
      filter((r) => !!r),
      tap((cI) => (this.croppedImage = cI)),
      switchMap((__) => this.saveImage())
    );
  }

  /**
   * Converts the Cropped DataURI into a Blob
   * @author Arslan
   * @date 2020-05-17
   * @private
   * @param {*} dataURI
   * @param {string} [mimeType]
   * @returns {Blob}
   * @memberof ImageUploaderComponent
   */
  private dataURItoBlob(dataURI, mimeType?: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    mimeType = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: mimeType});
  }

  ngOnDestroy() {
    this.unSubAll.next();
    this.unSubAll.complete();
  }
}
