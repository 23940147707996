<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchDefault>
    <img *ngIf="!isCms; else showImage"
      [ngClass]="classNames"
      [defaultImage]="defaultImage"
      [lazyLoad]="src"
      [useSrcset]="useSrcset"
      [ngStyle]="style"
      [width]="width || 'auto'"
      [height]="height || 'auto'"
      [alt]="alt"
    />
    <ng-template #showImage>
      <img
        [ngClass]="classNames"
        [src]="src"
        [ngStyle]="style"
        [width]="width || 'auto'"
        [height]="height || 'auto'"
        [alt]="alt"
      />
    </ng-template>
  </ng-container>
  
  

  <!-- TODO: Implementation of Below still pending -->
  <div
    *ngSwitchCase="'div'"
    [ngClass]="classNames"
    [defaultImage]="defaultImage"
    [lazyLoad]="src"
  >
    <ng-content></ng-content>
  </div>
  <picture *ngSwitchCase="'picture'">
    <source
      media="(min-width: {{ pictureObject.screen_lg || '1200px' }})"
      [attr.defaultImage]="defaultImage"
      [attr.lazyLoad]="pictureObject.image2"
    />
    <source
      media="(min-width: {{ pictureObject.screen_md || '992px' }})"
      [attr.defaultImage]="defaultImage"
      [attr.lazyLoad]="pictureObject.image3"
    />
    <img [defaultImage]="defaultImage" [lazyLoad]="pictureObject.image1" />
  </picture>
</ng-container>
