import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { FieldType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';

@Component( {
  selector: 'app-json-object-type',
  template: `
    <textarea rows="5" cols="60" [formControl]="jsonObjectControl" [formlyAttributes]="field"></textarea>
    <div *ngIf="hasError" class="invalid-feedback">{{ errorMessage }}</div>
  `,
} )
export class FormlyJsonObjectComponent extends FieldType {
  get jsonObjectControl (): FormControl {
    return this.field.formControl as FormControl;
  }

  get hasError (): boolean {
    return this.jsonObjectControl.touched && this.jsonObjectControl.invalid;
  }

  get errorMessage (): string {
    const errors: ValidationErrors = this.jsonObjectControl.errors;
    if ( errors ) {
      if ( errors.required ) {
        return 'Field is required';
      }
      if ( errors.invalidJson ) {
        return 'Invalid JSON';
      }
    }
    return '';
  }

  ngOnInit () {
    this.jsonObjectControl.valueChanges.subscribe( ( value ) => {
      let error: ValidationErrors = null;
      try {
        JSON.parse( value );
      } catch ( e ) {
        error = { invalidJson: true };
      }
      this.jsonObjectControl.setErrors( error );
    } );
  }
}



@NgModule( {
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule
    ],
  exports: [ FormlyJsonObjectComponent ],
  declarations: [ FormlyJsonObjectComponent ],
  providers: [],
} )

export class JsonObjectTypeComponentModule { }

