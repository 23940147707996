import {NgModule, ModuleWithProviders, Inject, Optional} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfigOptions} from './types/types.config';
import {DynamicPageComponent} from './dynamic-page.component';
import {
  COMPONENT_CONFIG,
  ConfigProviderService,
} from './providers/config-provider.service';
import {defaultConfig} from './components/component.configs';
import {
  EditorConfigService,
  EDITOR_CONFIG,
} from './providers/editor-config.service';
import {PipesModule} from '@ecommerce/ui-components/pipes/pipes.module';
import {InlineEditorModule} from './components/inline-editor/inline-editor.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RendererModule} from './components/renderer/renderer.module';
import {ActionBarModule} from '@ecommerce/ui-components/components/action-bar/action-bar.module';
import {WidgetActionbarModule} from '@ecommerce/ui-components/components/widget-actionbar/widget-actionbar.module';
import {VideoModalModule} from '@ecommerce/ui-components/components/modals/video-modal/video-modal.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [DynamicPageComponent],
  imports: [
    CommonModule,
    PipesModule,
    RendererModule,
    InlineEditorModule,
    FormsModule,
    ReactiveFormsModule,
    ActionBarModule,
    WidgetActionbarModule,
    VideoModalModule,
    MatSnackBarModule,
  ],
  exports: [DynamicPageComponent, InlineEditorModule, RendererModule],
})
export class DynamicPageModule {
  static forRoot(
    config: ConfigOptions = {},
    isCms: boolean = false
  ): ModuleWithProviders<DynamicPageModule> {
    return {
      ngModule: DynamicPageModule,
      providers: [
        {provide: COMPONENT_CONFIG, useFactory: defaultConfig, multi: true},
        {provide: COMPONENT_CONFIG, useValue: config, multi: true},
        {provide: EDITOR_CONFIG, useValue: isCms},
      ],
    };
  }
  static forChild(
    config: ConfigOptions = {}
  ): ModuleWithProviders<DynamicPageModule> {
    return {
      ngModule: DynamicPageModule,
      providers: [
        {
          provide: COMPONENT_CONFIG,
          useValue: config,
          multi: true,
        },
      ],
    };
  }
  constructor(
    @Optional() @Inject(COMPONENT_CONFIG) configs: ConfigOptions[] = [],
    configService: ConfigProviderService,
    @Optional() @Inject(EDITOR_CONFIG) isCms: boolean = false,
    editorConfigService: EditorConfigService
  ) {
    editorConfigService.isCMS = isCms;
    editorConfigService.init();

    if (!configs) {
      return;
    }
    
    configs.forEach((config) => configService.registerComponents(config));
  }
}
