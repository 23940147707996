<div class="formly-verify-wrapper" [class]="{'has-danger': !formControl.valid}">
  <div
    class="formly-field-label"
    [class]="{ 'has-error': (formControl.invalid && formControl.touched) }"
  >
    {{to.label}}
  </div>
  <ng-container
    [ngTemplateOutlet]=" currentStep === 'step1' ? step1 : currentStep ===
      'step2' ? step2 : step3"
    [ngTemplateOutletContext]="{ to: to }"
  >
  </ng-container>
  <div class="compliance-container">
    <div class="compliance" *ngIf="currentStep !== 'step3'">
      <mat-icon color="primary" class="info-icon mr-8">info</mat-icon>
      <span>
        We'll only contact you about this inquiry. Nothing else.
      </span>
    </div>
  </div>
  <ng-container *ngIf="to.showSpecs">
    <br />
    <pre>TO: {{to | json}}</pre>
    <pre>CurrentStep: {{currentStep | json}}</pre>
    <pre>Status: {{status | json}}</pre>
    <pre>Loading: {{loading|json}}</pre>
    {{error}}
  </ng-container>
</div>

<!-- Enter Phone -->
<ng-template #step1>
  <div class="input-container">
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div [class]="'input-phone ' + (resendCount > 0 ? 'slide-out' : '')">
      <!-- Phone Input with Submit here -->
      <mat-form-field appearance="outline">
        <mat-label>Phone Number</mat-label>
        <input matInput type="input" name="phone" [formControl]="fc" />
        <mat-error *ngIf="formControl.hasError('required')"
          >Phone is Required</mat-error
        >
        <mat-error *ngIf="formControl.hasError('pattern')"
          >Invalid Phone Number, Only UK Cell Phone Allowed</mat-error
        >
        <mat-error *ngIf="formControl.hasError('api-error')"
          >{{error}}</mat-error
        >
      </mat-form-field>
      <button
        class="action-btn"
        mat-flat-button
        color="accent"
        (click)="submitPhone()"
      >
        Send Code
      </button>
    </div>
  </div>
</ng-template>
<!-- Verify -->
<ng-template #step2>
  <div class="input-container">
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div class="input-code">
      <!-- Code Input with Submit here -->
      <mat-form-field appearance="outline">
        <mat-label>Input Code</mat-label>
        <input matInput type="input" name="code" [formControl]="codeInput" />
        <mat-error *ngIf="codeInput.hasError('required')"
          >Phone is Required</mat-error
        >
        <mat-error *ngIf="codeInput.hasError('api-error')">{{error}}</mat-error>
      </mat-form-field>
      <button
        class="action-btn"
        mat-flat-button
        color="accent"
        (click)="submitCode()"
      >
        Verify Code
      </button>
    </div>
    <div class="time-left" *ngIf="!to.hideTimer">
      Verification Code Expires in: {{expiresIn}}s
    </div>
  </div>
</ng-template>
<!-- Thankyou -->
<ng-template #step3>
  <div class="thankyou">
    <!-- Thank you step -->
    <p>Thank you for your enquiry</p>
    <div class="message">
      <!-- <mat-icon class="icon accent-fg">check_circle</mat-icon> -->
      <!-- <i class="s-98 primary-fg icon icon-checkbox-marked-circle"></i> -->
      <p>{{to.verifiedMessage || "You phone has been verified!"}}</p>
    </div>
    <div class="action" *ngIf="!to.hideActionBtn">
      <button
        class="verified-btn"
        mat-flat-button
        color="accent"
        routeTransformer
        (click)="actionHandler($event)"
      >
        {{to.actionLabel || "OK"}}
      </button>
    </div>
  </div>
</ng-template>
