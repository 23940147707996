<div
  class="navbar-top"
  [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
>
  <div class="logo">
    <img
      class="logo-icon"
      [src]="config?.company?.logo?.url || 'assets/images/logos/fuse.svg'"
    />
    <span class="logo-text secondary-text">{{config?.company?.name || 'Sample Corp'}}</span>
  </div>

  <div class="buttons">
    <button
      mat-icon-button
      class="toggle-sidebar-folded"
      (click)="toggleSidebarFolded()"
      fxHide.lt-lg
    >
      <mat-icon class="secondary-text">menu</mat-icon>
    </button>

    <button
      mat-icon-button
      class="toggle-sidebar-opened"
      (click)="toggleSidebarOpened()"
      fxHide.gt-md
    >
      <mat-icon class="secondary-text">arrow_back</mat-icon>
    </button>
  </div>
</div>

<div
  class="navbar-scroll-container"
  [ngClass]="fuseConfig.layout.navbar.primaryBackground"
>
  <div
    class="user"
    fxLayout="column"
    [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
  >
    <div class="h3 username">{{ user?.fullName || '--' }}</div>
    <div class="h5 text-center email hint-text mt-8">
      {{ user?.username || '--'}} <br>
      {{ user?.email || '--' }}
    </div>
    <div
      class="avatar-container"
      [ngClass]="fuseConfig.layout.navbar.primaryBackground"
    >
      <img
        class="avatar"
        [src]="
          user?.profile?.picture?.url || 'assets/images/avatars/Velazquez.jpg'
        "
      />
    </div>
  </div>

  <div class="navbar-content">
    <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
  </div>
</div>
