import { ConfigOptions } from '../types/types.config';
import { normalizeCommonJSImport } from '@ecommerce/utils/cJSImports';

const WidgetGroupComponent = normalizeCommonJSImport( () =>
  import(
    '@ecommerce/ui-components/modules/dynamic-page/components/row-container/row-container.component'
  )
);
export function defaultConfig (): ConfigOptions {
  return {
    // types: [ { type: 'widget-group', component: WidgetGroupComponent } ],
  };
}
