import { Component, OnInit, NgModule, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@Component( {
  // tslint:disable-next-line: component-selector
  selector: 'formly-field-dyn-template',
  encapsulation: ViewEncapsulation.None,
  template: `
    <span [ngClass]="to.class || ''">{{ this.fc?.value }}</span>`,
  styles: [],
} )
export class FormlyDynamicTemplateComponent
  extends FieldType
  implements OnInit {
  fc;
  ngOnInit (): void {
    this.fc = this.formControl;
  }
}

@NgModule( {
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  exports: [],
  declarations: [ FormlyDynamicTemplateComponent ],
  providers: [],
} )
export class FormlyFieldDynamicTemplateModule { }
