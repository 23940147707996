import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FloatBarComponent } from './float-bar.component';



@NgModule( {
  declarations: [ FloatBarComponent ],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports: [ FloatBarComponent ]
} )
export class FloatBarModule { }
