<div
  class="formly-mat-boxradio-wrapper"
  [class]="{'has-danger': !formControl.valid}"
>
  <div
    class="formly-field-label"
    [class]="{ 'has-error': (formControl.invalid && formControl.touched) }"
  >
    {{to.label}}
  </div>
  <div class="boxradio-group">
    <div
      class="boxradio-button"
      *ngFor="let option of opts; let i = index;"
    >
      <input
        type="radio"
        [id]="id + '_' + i"
        [formControl]="fc"
        [name]="(to.group || to.label)"
        [value]="option.value"
        (click)="change($event)"
      />
      <label for="{{id + '_' + i}}">
        <div class="heading">
          <i *ngIf="option.icon" [class]="'icon ' + option.icon"></i>
          <div
            *ngIf="option.svg"
            class="icon-svg"
            aria-label="My icon"
            [inlineSVG]="'/assets/icons/svgs/' + option.svg"
            [setSVGAttributes]="{
                  class: '',
                  width: 60,
                  height: 60
                }"
          ></div>
          <comp-image 
           *ngIf="option.image" [src]="option.image" classNames="image" >
          </comp-image>
          <div class="accent-bg label-text">
            <span class="grey-50-fg text text-bold"
              >{{option.label || option.value}}</span
            >
          </div>
        </div>
        <p *ngIf="option.description">{{option.description}}</p>
      </label>
    </div>
  </div>
</div>
