import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'comp-service-select-modal',
  templateUrl: './service-select-modal.component.html',
  styleUrls: ['./service-select-modal.component.scss'],
})
export class ServiceSelectModalComponent implements OnInit {
  services = [
    {
      link: 'install',
      svgId: 'new-boiler',
      name: 'New Boiler',
      svgClass: 'primary-svg',
    },
    {
      link: 'new',
      svgId: 'central-heating',
      name: 'Central Heating',
      svgClass: 'accent-dark-svg',
    },
    {
      link: 'repair',
      svgId: 'service-repair',
      name: 'Boiler Repair',
      svgClass: 'accent-svg',
    },
    {
      link: 'service',
      svgId: 'compare-boiler',
      name: 'Boiler Service',
      svgClass: 'warn-svg',
    },
  ];
  constructor(
    public dialogRef: MatDialogRef<ServiceSelectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {}
  selectService(ser) {
    if (ser && ser.link) {
      this.dialogRef.close(ser.link);
    }
  }
}
