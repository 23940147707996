import { NgModule } from '@angular/core';

import { VideoModalComponent } from './video-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@ecommerce/ui-components/pipes/pipes.module';
import { YoutubePlayerModule } from '../../youtube-player/youtube-player.module';

@NgModule({
  imports: [CommonModule, MatDialogModule, YoutubePlayerModule],

  entryComponents: [VideoModalComponent],
  exports: [VideoModalComponent],
  declarations: [VideoModalComponent],
  providers: [],
})
export class VideoModalModule {}
