<div id='snav'
     class='en'>

  <ul>
    <li *ngFor="let item of items">
      <a (click)="selectItem(item)">
        <mat-icon>{{item.icon}}</mat-icon>
        <span>{{item.name}}</span>
      </a>
    </li>

  </ul>

</div>
