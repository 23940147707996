import { Component, ViewEncapsulation, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ReactiveFormsModule,
  Validators,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg';
import {
  MessageBirdApisControllerClient,
  VerifyResponseVm,
  VerifyTokenVm,
  ApiException,
} from '@ecommerce/ui-services/client-api';
import { filter, tap, takeUntil, take } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { UiDirectivesModule } from '@ecommerce/ui-directives/ui-directives.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { merge, timer, Subject, interval } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-field-verify-phone',
  templateUrl: 'formlyfield.verifyphone.html',
  styleUrls: ['formlyfield.verifyphone.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyFieldVerifyPhone
  extends FieldType
  implements OnInit, OnDestroy {
  error: string;
  unSubAll = new Subject();
  fc: any;
  constructor(private readonly _phoneService: MessageBirdApisControllerClient, private readonly _changeRef: ChangeDetectorRef) {
    super();
  }

  private vR: VerifyResponseVm;
  loading = false;

  // phoneInput = new FormControl('', [Validators.required]);
  codeInput = new FormControl('', [Validators.required]);

  expiresIn: number;
  verified$: Subject<any> = new Subject();
  resendCount = 0;

  status:
    | 'sent'
    | 'expired'
    | 'failed'
    | 'verified'
    | 'deleted'
    | 'none'
    | string = 'none';

  get currentStep() {
    if (this.status === 'sent' || this.status === 'failed' || !this.status) {
      return 'step2';
    } else if (this.status === 'verified') {
      return 'step3';
    } else return 'step1'; // Status 'none' | 'expired' | 'deleted'
  }

  ngOnInit() {
    this.fc = this.formControl;
    merge([this.formControl.valueChanges, this.codeInput.valueChanges])
      .pipe(takeUntil(this.unSubAll))
      .subscribe((__) => (this.error = null));

    this.to.codeExpiresInSeconds = this.to.codeExpiresInSeconds || 60;
    this.expiresIn = this.to.codeExpiresInSeconds;
  }

  ngOnDestroy() {
    this.unSubAll.next();
    this.unSubAll.complete();
  }

  submitPhone() {
    if (this.formControl.invalid) return;
    this.loading = true;

    this._phoneService
      .verifyCreate(this.formControl.value, this.to.codeExpiresInSeconds)
      .pipe(
        takeUntil(this.unSubAll),
        tap((__) => (this.loading = false)),
        filter((vr) => !!vr),
        tap((vr) => console.log(vr)),
        tap((vr) => (this.status = vr.status)),
      )
      .subscribe(
        (vr) => {
          this.loading = false;
          this.vR = vr;
          this.change({
            contact_phone: this.formControl.value,
            verified: false,
          });
          // if (vr.status === 'sent') {
          // Removing 'sent' check to take to next screen
          this.startTimer();
          // }
        },
        (error) => this.setErrors(error, this.formControl));
  }

  submitCode() {
    if (this.codeInput.invalid) return;
    this.loading = true;

    this._phoneService
      .verifyToken(
        new VerifyTokenVm({id: this.vR.id, token: this.codeInput.value})
      )
      .pipe(
        takeUntil(this.unSubAll),
        tap((vr) => console.log(vr)),
        filter((vr) => !!vr),
        tap((vr) => {
          this.status = vr.status;
        }),
        tap((__) => (this.loading = false)),
        filter((vr) => vr.status === 'verified')
      )
      .subscribe(
        (vr) => {
          this.onVerified();
        },
        (error) => this.setErrors(error, this.codeInput)
      );
  }
  resendCode() {
    if (this.resendCount <= (this.to.resendCount || 3)) {
      this.resendCount += 1;
      this.expiresIn = this.to.codeExpiresInSeconds;
      this.status = 'none';
    }
  }

  setErrors(error: ApiException, formControl: AbstractControl) {
    console.log(error);
    formControl.setErrors({
      'api-error': true,
    });
    this.error =
      error.errors[0].code === 20 || error.errors[0].code === 10
        ? 'Invalid Code'
        : 'Error Processing Input';
    this.loading = false;
  }
  startTimer() {
    interval(1000)
      .pipe(
        takeUntil(this.verified$),
        take(this.to.codeExpiresInSeconds + 1 || 30)
      )
      .subscribe((x) => {
        this.expiresIn -= 1;
        this._changeRef.markForCheck();
        this._changeRef.detectChanges();
        if (this.expiresIn <= 0) {
          this.resendCode();
        }
      });
  }
  change($event) {
    if (this.to.change) {
      this.to.change(this.field, $event);
    }
  }
  onVerified() {
    // this.formControl.setValue(true);
    
    this.change({contact_phone: this.formControl.value, verified: true});
    this.status = 'verified';
    this.verified$.next();
    this.verified$.complete();
    
    this._changeRef.markForCheck();
    this._changeRef.detectChanges();
  }
  actionHandler(event){}
}

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    UiDirectivesModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
  exports: [],
  declarations: [FormlyFieldVerifyPhone],
  providers: [],
})
export class FormlyFieldVerifyModule {}
