<div class="formly-mat-autocomplete-wrapper">
  <input
    matInput
    [placeholder]="to.placeholder"
    [matAutocomplete]="auto"
    [formControl]="fc"
  />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="to.displayFn">
    <mat-option
      *ngFor="let option of to.opts | async"
      [value]="option.value"
    >
      <i class="icon icon-map-marker"></i>
      <span>{{ option.label }}</span>
      <span *ngIf="option.sublabel">
        |
        <small>{{ option.sublabel }}</small>
      </span>
    </mat-option>
  </mat-autocomplete>
</div>
