import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, NgModule, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import {
  RecaptchaModule,
  RecaptchaFormsModule,
} from 'ng-recaptcha';
import { environment } from "@ecommerce/environments/client/environment";
import { EnvVariableApiService } from '@ecommerce/ui-services/application/environment.variable.service';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'comp-re-captcha',
  templateUrl: './re-captcha.component.html',
  styleUrls: ['./re-captcha.component.scss'],
})
export class FormlyFieldReCaptchaComponent extends FieldType implements OnInit, OnDestroy {
  fc;
  opts;
  siteKey : string;
  unSubAll = new Subject();
  constructor(private readonly _applicationConfig: EnvVariableApiService, private ref: ChangeDetectorRef){
    super();
  }
  ngOnInit(): void {
    this.fc = this.formControl;
    this.opts = this.to.options;
    this._applicationConfig.configChanges
    .pipe(
      takeUntil(this.unSubAll),
      filter(v => !!(v && v.environmentConfig && v.environmentConfig.thirdPartyIntKeys)),
      tap(v => {
        this.siteKey = v.environmentConfig.thirdPartyIntKeys.recaptchaSiteKey;
        this.ref.markForCheck();
        this.ref.detectChanges();
      }),
    )
    .subscribe();
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.unSubAll.next();
    this.unSubAll.complete();
  }
}


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  exports: [],
  declarations: [FormlyFieldReCaptchaComponent],
  providers: [],
})
export class FormlyFieldReCaptchaComponentModule {}