<div class="upload-container">
  <div [class]="position + ' upload-btn-wrapper'" *ngIf="editible">
    <input
      #inputFile
      hidden="true"
      type="file"
      accept="image/x-png,image/jpeg,image/jpg"
      name="myfile"
      (change)="fileChangeEvent($event)"
    />
    <button
      *ngIf="!uploading"
      mat-fab
      class="btn"
      (click)="selectFile($event, inputFile)"
    >
      <i [class]="image?.url ? ' icon-camera-party-mode' : ' icon-upload'"></i>
    </button>
    <mat-spinner
      [diameter]="50"
      [strokeWidth]="5"
      color="accent"
      *ngIf="uploading"
    ></mat-spinner>
  </div>
  <div [ngClass]="editible && 'overlay'" *ngIf="showOverlay"></div>
  <div
    class="content"
    [ngStyle]="{
      width: cropperMinWidth || '100%',
      height: cropperMinHeight || '100%',
      'background-position-x': imagePositionX,
      'background-position-y': imagePositionY,
      'background-size': contain,
      'background-attachment': parallex ? 'fixed' : 'inherit'
    }"
  >
    <ng-content></ng-content>
    <!-- <div id="container"
         [class]="contentPosition">
    </div> -->
  </div>
</div>
