// Inserts an item in array position
function insert<T>(arr: T[], location: number, item: any): Array<T> {
  arr.splice(location, 0, item);
  return arr;
}

function push<T>(arr: T[], item: T): Array<T> {
  arr.push(item);
  return arr;
}

// Moves an item from once position to another
function move<T>(arr: T[], to: number, from: number): Array<T> {
  // Save the item
  const item = arr[from];
  // Remove it from array
  arr.splice(from, 1);

  return insert<T>(arr, to, item);
}

function deleteItem<T>(arr: T[], from: number): Array<T> {
  arr.splice(from, 1);
  return arr;
}

function argumentsToArgsArray(a: IArguments): Array<any> {
  return Array.prototype.slice.call(a);
}

function includesAny<T, K>(
  arr: Array<T>,
  searchArray: Array<K | T>,
  searchFrom: number = 0,
  searchFn?: (a: T, b: K) => boolean
): boolean {
  if (!arr) return false;
  if (!Array.isArray(arr)) return false;
  if (searchFrom >= arr.length) return false;
  for (let i = searchFrom; i < searchArray.length; i++) {
    if (searchFn) {
      if (
        arr.filter(
          (v) => searchArray.findIndex((s) => searchFn(v, s as K)) > -1
        ).length > 0
      )
        return true;
    } else {
      if (arr.includes(searchArray[i] as T)) return true;
    }
  }
  return false;
}
function includesAll<T, K>(
  arr: Array<T>,
  searchArray: Array<T | K>,
  searchFrom: number = 0,
  searchFn?: (a: T, b: K) => boolean
): boolean {
  if (!arr) return false;
  if (!Array.isArray(arr)) return false;
  if (searchFrom >= arr.length) return false;
  for (let i = searchFrom; i < searchArray.length; i++) {
    if (searchFn) {
      if (
        arr.filter(
          (v) => searchArray.findIndex((s) => searchFn(v, s as K)) > -1
        ).length === searchArray.length
      )
        return true;
    } else {
      if (!arr.includes(searchArray[i] as T)) return false;
    }
  }
  return true;
}

function intersection(ar1: any[], ar2: any[]) {
  return ar1.filter(v => ar2.includes(v));
}

export const ArrayUtils = {
  insert,
  move,
  push,
  deleteItem,
  argumentsToArgsArray,
  includesAll,
  includesAny,
  intersection,
};
