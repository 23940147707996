<div class="theme-options-panel" fusePerfectScrollbar>
  <div class="header">
    <span class="title">Theme Options</span>

    <button
      mat-icon-button
      class="close-button"
      (click)="toggleSidebarOpen('themeOptionsPanel')"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="form">
    <!-- COLOR THEME -->
    <div class="group">
      <h2>Color themes</h2>

      <mat-radio-group
        fxLayout="column"
        fxLayoutAlign="start start"
        formControlName="colorTheme"
      >
        <mat-radio-button class="mb-12" value="theme-default"
          >Default Light</mat-radio-button
        >
        <mat-radio-button class="mb-12" value="theme-yellow-light"
          >Yellow Light</mat-radio-button
        >
        <mat-radio-button class="mb-12" value="theme-blue-gray-dark"
          >Blue-Gray Dark</mat-radio-button
        >
        <mat-radio-button class="mb-12" value="theme-pink-dark"
          >Pink Dark</mat-radio-button
        >
      </mat-radio-group>
    </div>
  </form>
</div>
