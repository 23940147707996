import { NgModule, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InlineEditorComponent } from './inline-editor.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { QuillModule } from '../../../../components/quill-editor/quill.module';
// import { QuillEditorModule } from '@ecommerce/ui-components/components/quil-editor/quillEditor.module';

@NgModule({
  declarations: [InlineEditorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [InlineEditorComponent, FormsModule, ReactiveFormsModule],
  providers: [],
})
export class InlineEditorModule {}
