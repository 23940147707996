import {Injectable, PLATFORM_ID, Inject} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {isPlatformServer} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(PLATFORM_ID) private platformId: string) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // If Intercepted on Server then simply ignore interception
    if (isPlatformServer(this.platformId)) return next.handle(req);

    const idToken = localStorage.getItem('token');
    if (idToken) {
      const cloned = req.clone({
        headers: req.headers.append('Authorization', 'Bearer ' + idToken),
      });

      return next.handle(cloned);
    }

    return next.handle(req);
  }
}

export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
