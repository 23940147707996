import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg';
import { DexImageModule } from '@ecommerce/ui-components/components/dex-image/dex-image.module';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-field-boxradio',
  templateUrl: 'formlyfield.boxradio.html',
  styleUrls: ['formlyfield.boxradio.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyFieldBoxRadio extends FieldType implements OnInit{
  fc;
  opts;
  ngOnInit(): void {
    this.fc = this.formControl;
    this.opts = this.to.options;
  }
  change($event) {
    if (this.to.change) {
      this.to.change(this.field, $event);
    }
  }
}

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, InlineSVGModule, DexImageModule],
  exports: [],
  declarations: [FormlyFieldBoxRadio],
  providers: [],
})
export class FormlyFielBoxRadioModule {}
