import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

import {HorizontalLayout1Module} from './horizontal/layout-1/layout-1.module';
import {LayoutComponent} from './layout.component';
import {CommonModule} from '@angular/common';
import {FuseSidebarModule} from '@fuse-cs/components/sidebar/sidebar.module';
import {FuseProgressBarModule} from '@fuse-cs/components/progress-bar/progress-bar.module';
import {FuseThemeOptionsModule} from '@fuse-cs/components/theme-options/theme-options.module';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FuseSidebarModule,
    FuseProgressBarModule,
    FuseThemeOptionsModule,
    HorizontalLayout1Module,
  ],
  exports: [LayoutComponent, HorizontalLayout1Module],
})
export class LayoutModule {}
