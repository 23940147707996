import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseCsSharedModule } from '@fuse-cs/shared.module';

import { ContentComponent } from '../../components/content/content.component';

@NgModule({
  declarations: [ContentComponent],
  imports: [RouterModule, FuseCsSharedModule],
  exports: [ContentComponent],
})
export class ContentModule {}
