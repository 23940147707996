import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';

import {FUSE_CONFIG} from '@ecommerce/ui-services/fuse/config.service';
import {DEFAULT_SETTING} from '@ecommerce/ui-services/fuse/settings-api.service';
@NgModule()
export class FuseCsModule {
  constructor(@Optional() @SkipSelf() parentModule: FuseCsModule) {
    if (parentModule) {
      throw new Error(
        'FuseModule is already loaded. Import it in the AppModule only!'
      );
    }
  }

  static forRoot({
    config,
    navigation,
    configName,
  }): ModuleWithProviders<FuseCsModule> {
    return {
      ngModule: FuseCsModule,
      providers: [
        {
          provide: FUSE_CONFIG,
          useValue: config,
        },
        {
          provide: DEFAULT_SETTING,
          useValue: {config, navigation, configName},
        },
      ],
    };
  }
}
