import { NgModule } from '@angular/core';

import { YoutubePlayerComponent } from './youtube-player.component';

@NgModule({
  imports: [],
  exports: [YoutubePlayerComponent],
  declarations: [YoutubePlayerComponent],
  providers: [],
})
export class YoutubePlayerModule {}
