import {Injectable, InjectionToken} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@ecommerce/ui-services/auth/auth.service';
import {Roles} from '@ecommerce/ui-services/client-api';
import {BehaviorSubject, combineLatest, merge, of} from 'rxjs';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {NavigationStart, Router, RouterEvent} from '@angular/router';

export const EDITOR_CONFIG = new InjectionToken<EditorConfigService>(
  'EDITOR_CONFIG'
);

@Injectable({providedIn: 'root'})
export class EditorConfigService {
  editible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private lockEditors$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isCMS: boolean;
  get editible() {
    return this.editible$.value;
  }
  set editible(value) {
    this.editible$.next(value);
  }
  get lockEditors() {
    return this.lockEditors$.value;
  }
  set lockEditors(value) {
    this.lockEditors$.next(value);
  }
  constructor(private authService: AuthService, private router: Router) {}
  init() {
    if(!this.isCMS) {
      this.editible = false;
      this.lockEditors = true;
      return;
    }

    this.authService.init();

    // Making Sure only Admin Can Edit Pages
    combineLatest([this.authService.rolesChanges, this.lockEditors$.asObservable()])
      .pipe(
        filter(([roles]) => !!roles),
        filter(
          ([roles]) =>
            roles.includes(Roles.Admin) ||
            roles.includes(Roles.Csr) ||
            roles.includes(Roles.ContentManager) ||
            roles.includes(Roles.Blogger)
        )
      )
      .subscribe(() => {
        if (!this.lockEditors) this.editible = true;
        else {
          this.editible = false;
        }
      });

   this. router.events
      .pipe(
        filter((e) => e instanceof NavigationStart),
        tap(() => (this.lockEditors = true))
      )
      .subscribe();

  }
}
