import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';

@Injectable()
export class ReturnUrlService {
  private returnUrl$: BehaviorSubject<string> = new BehaviorSubject('/');

  get url() {
    return this.returnUrl$.getValue();
  }

  set url(value: string) {
    this.returnUrl$.next(value);
  }

  valueChanges() {
    return this.returnUrl$.asObservable();
  }

  reset() {
    this.returnUrl$.next('/');
  }

  constructor() {}
}
