<mat-toolbar color="accent" class="toolbar">
  <span>Add new Widget</span>
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<div mat-dialog-content>
  <div class="container">
    <comp-float-bar
      [items]="floatItems"
      (selectedItem)="getItems($event)"
    ></comp-float-bar>
    <div class="widget-container">
      <div class="loading-container">
        <mat-progress-spinner
          *ngIf="loading"
          class="spinner"
          mode="indeterminate"
          [strokeWidth]="3.5"
          [diameter]="100"
          color="primary"
        ></mat-progress-spinner>
      </div>
      <ng-container *ngIf="!loading">
        <mat-form-field class="input-search" appearance="fill">
          <mat-icon matPrefix class="my-icon">search</mat-icon>
          <mat-label>Search Widgets</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="value"
            (ngModelChange)="searchText($event)"
          />
        </mat-form-field>
      </ng-container>
      <mat-progress-spinner 
       *ngIf="loading1"
       class="spinner"
       mode="indeterminate"
       [strokeWidth]="3.5"
       [diameter]="100"
       color="primary" style="margin: auto;"></mat-progress-spinner>
      <div class="widgets" *ngFor="let widget of widgets$ | async">
        <div  *ngIf="!loading1">
        <div class="widget" (click)="addWidget(widget)">
          <img [src]="widget?.image?.url" alt="" class="image" />
          <div class="name">{{ widget.name }}</div>
        </div>
      </div>
      </div>
      <div
        [hidden]="limitReached()"
        infiniteScroll
        [enable]="true"
        (loadMore)="loadMore($event)"
        [scrollLimitReached]="limitReached()"
        fxFlex="100"
        class="load-more"
      >
        <mat-progress-spinner
          class="spinner"
          mode="indeterminate"
          [strokeWidth]="3.5"
          [diameter]="50"
          color="primary" style="margin: auto;"
        ></mat-progress-spinner>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions></div>
