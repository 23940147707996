<div
  class="formly-mat-array-toggle-wrapper"
  [class]="{'has-danger': !formControl.valid}"
>
  <!-- <div [class]="to.containerClass || 'column'"></div> -->
  <ng-container
    [ngTemplateOutlet]="to.appearance === 'list' ? listWrapper : noWrapper"
  ></ng-container>
</div>

<!-- List Wrpper -->
<ng-template #listWrapper>
  <mat-list>
    <mat-list-item *ngFor="let option of opts">
      <ng-container
        [ngTemplateOutlet]="toggle"
        [ngTemplateOutletContext]="{option: option}"
      ></ng-container>
    </mat-list-item>
  </mat-list>
</ng-template>
<!-- No Wrapper -->
<ng-template #noWrapper>
  <ng-container
    *ngFor="let option of opts"
    [ngTemplateOutlet]="toggle"
    [ngTemplateOutletContext]="{option: option}"
  ></ng-container>
</ng-template>

<!-- Toggle Template -->
<ng-template #toggle let-option="option">
  <mat-slide-toggle
    class="toggle"
    [color]="to.color"
    [labelPosition]="to.labelPosition || 'after'"
    [checked]="getChecked(option.label || option.value)"
    (change)="onChange($event, option.label || option.value)"
  >
    {{option.label || option.value}}
  </mat-slide-toggle>
</ng-template>
