import {
  Directive,
  HostListener,
  ElementRef,
  Input,
  OnInit,
  AfterContentInit,
  AfterContentChecked,
  AfterViewChecked,
} from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[routeTransformer]',
})
export class RouteTransformerDirective {
  @Input() prefix: string;
  @Input() suffix: string;
  constructor(private el: ElementRef, private router: Router) {}
  @HostListener('click', ['$event'])
  public onClick(event) {
    if (
      event.target.tagName === 'A' ||
      event.target.parentElement.tagName == 'A'
    ) {
      const targetAElement =
        event.target.tagName === 'A'
          ? event.target
          : event.target.parentElement;
      event.preventDefault();
      const currentUrl = new URL(window.location.href);

      let url;
      try {
        url = new URL(targetAElement.getAttribute('href'));
      } catch (e) {
        url = new URL(targetAElement.getAttribute('href'), currentUrl.origin);
      }

      if (currentUrl.hostname !== url.hostname) {
        window.open(targetAElement.getAttribute('href'));
        return;
      }

      if (this.prefix) this.router.navigate([this.prefix + url.pathname]);
      if (this.suffix)
        this.router.navigate([
          targetAElement.getAttribute('href') + this.suffix,
        ]);
      else this.router.navigate([url.pathname]);
    } else {
      return;
    }
  }
  // ngAfterViewChecked() {
  //   console.log('View Checked!');
  // }
  // ngAfterContentChecked(): void {
  //   console.log('Content Checked!');
  //   //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  //   //Add 'implements OnInit' to the class.

  //   const hrefs = this.el.nativeElement.getElementsByTagName('a');
  //   for (let i = 0; i < hrefs.length; i++) {
  //     const a = hrefs.item(i);
  //     if (a.href && a.href.charAt(a.href.length - 1) !== '/') a.href += '/';
  //   }
  // }
}
