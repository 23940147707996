import {
  TemplateOptionsVm,
  TemplateOptionsVmFlex,
  ComponentConfigVm,
} from '@ecommerce/ui-services/client-api';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {ObjectId} from 'bson';
import {cloneDeep} from 'lodash-es';

const bannerWithVideoData = {
  image: {
    id: '5efd24830618a15f8f3d4dfb',
    url: 'https://boiler-staging.s3.eu-west-2.amazonaws.com/assets/images/largefiles/463745-Header%201.jpg-original',
  },
  settings: {
    contain: 'cover',
    fullWidth: true,
    hasOverlay: true,
    hasVideo: true,
    height: 400,
    heightType: 'px',
    hideContent: true,
    imagePositionX: '50%',
    imagePositionY: '50%',
    overlayClass: 'bg-linear-gradian-accent',
    overlayOpacity: '0.8',
    videoUrl: 'https://www.youtube.com/watch?v=0Z1elAW2U-M',
  },
};

const bannerWithImageData = cloneDeep({
  ...bannerWithVideoData,
});

bannerWithImageData.settings.hasVideo = false;
bannerWithImageData.settings.hasOverlay = false;

const textWidgetData = {
  title: '<h2 class="ql-align-center">Title Text Comes here</h2>',
  description:
    '<p>Write some nice search engine optimized description here.</p><p>You can also use bullets here like below:</p><ul><li>This will be bullet 1</li><li>This will be bullet 2</li><li>This will be bullet 3</li></ul><p><br></p><p>You can also write <span class="ql-size-large" style="color: rgb(255, 153, 0);">TEXT LIKE THIS</span> or be smart with the text and create <a href="/" rel="noopener noreferrer" target="_blank">links like this</a>.</p><p><br></p><p>You can use the settings button on the right of this widget and enable Action Buttons to display or hide the action button below:</p>',
  settings: {
    fullWidth: false,
    actionBtnText: 'Action Text',
    showActionBtn: true,
    containerClass: 'bg-default',
  },
};

export interface WIDGETTEMPLATE {
  name: string;
  image: string;
  group: string[];
  widget: {
    _id?: string;
    type: String;
    image?: any;
    title?: string;
    subTitle?: string;
    description?: string;
    data?: any;
    children?: any;
    to?: {};
  };
}
export const sampleWidgetsTemplates = (): WIDGETTEMPLATE[] => [
  // Banners
  // Banner-w5

  {
    name: 'Banner with Text',
    group: ['banner'],
    widget: {
      type: 'banner-w5',
      data: {},
      to: null,
    },
    image: 'assets/images/widgets/banner-3.PNG',
  },
  {
    name: 'Banner with Carousal',
    group: ['carousel', 'content', 'banner'],
    widget: {
      _id: new ObjectId().toHexString(),
      type: 'carousel-w1',
      data: {
        title:
          '<h1 class="ql-align-center"><span class="ql-size-large" style="color: rgb(255, 255, 255);">My Awesome Heading</span></h1>',
        subtitle:
          '<p class="ql-align-center"><span class="ql-size-large" style="color: rgb(255, 153, 0);">We provide you the best</span></p>',
        image: {
          url: 'assets/images/backgrounds/default-bg.PNG',
        },
        settings: {
          contain: 'cover',
          fullWidth: true,
          parallex: true,
          heightType: 'px',
        },
        slider: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
        ],
      },
      to: null,
    },
    image: 'assets/images/widgets/carousel-1.PNG',
  },
  {
    name: 'Banner with Two Column Text',
    group: ['banner'],
    widget: {
      _id: new ObjectId().toHexString(),
      type: 'banner-w8',
      data: {},
      to: null,
    },
    image: 'assets/images/widgets/banner-5.PNG',
  },
  {
    name: 'Banner with Video',
    group: ['banner'],
    widget: {
      _id: new ObjectId().toHexString(),
      type: 'banner-w5',
      data: cloneDeep({...bannerWithVideoData}),
      to: null,
    },
    image: 'assets/images/widgets/banner-video.png',
  },

  // Counters
  {
    name: 'Counter Type 1',
    group: ['counter'],
    widget: {
      type: 'counter-w1',
      _id: new ObjectId().toHexString(),
      data: {
        image: {
          id: '5eed047b607d7a2bae9c531d',
          url: 'assets/images/widgets/counter-v1.PNG',
        },
        settings: {
          backgroundImage: true,
          fullWidth: true,
          hasOverlay: true,
          overlayClass: 'bg-linear-gradian-accent',
          overlayOpacity: '0.9',
          textColor: 'grey-50-fg',
        },
        title:
          '<h1>My Awesome Heading</h1><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>',
        items: [
          {name: 'Title 1', value: 1210},
          {name: 'Title 2', value: 3},
          {name: 'Title 3', value: 14010},
          {name: 'Title 4', value: 4},
        ],
      },
    },
    image: 'assets/images/widgets/counter-v1.PNG',
  },
  {
    name: 'Counter Type 2',
    group: ['counter'],
    widget: {
      type: 'counter-w2',
      _id: new ObjectId().toHexString(),
      data: {
        image: {
          id: '5eed047b607d7a2bae9c531d',
          url: 'assets/images/widgets/counter-v2.PNG',
        },
        settings: {
          contain: 'cover',
          fullWidth: true,
          hasOverlay: true,
          height: 250,
          heightType: 'px',
          imagePositionX: null,
          imagePositionY: null,
          overlayClass: 'bg-linear-gradian-accent',
          overlayOpacity: '0.5',
          parallex: true,
          position: null,
          textColor: 'grey-50-fg',
        },
        items: [
          {name: 'Title 1', value: 1210},
          {name: 'Title 2', value: 3},
          {name: 'Title 3', value: 14010},
          {name: 'Title 4', value: 4},
        ],
      },
    },
    image: 'assets/images/widgets/counter-v2.PNG',
  },
  {
    name: 'Case Studies',
    group: ['cta'],
    widget: new ComponentConfigVm({
      // _id: new ObjectId().toHexString(),
      type: 'banner-w1',
      to: null,
    }) as any,
    image: 'assets/images/widgets/case-studies.png',
  },
  {
    name: 'CTA Type 3',
    group: ['cta'],
    widget: {
      type: 'cta-2',
      data: {},
    },
    image: 'assets/images/widgets/inline-cta-3.PNG',
  },

  // Contact
  {
    name: 'Contact Us',
    group: ['banner'],
    widget: {
      type: 'contact-info',
      data: {},
    },
    image: 'assets/images/widgets/contact-info.PNG',
  },
  {
    name: 'Latest News and Articles',
    group: ['banner', 'content'],
    widget: {
      type: 'articles-w1',
      data: {},
      to: null,
    },
    image: 'assets/images/widgets/news-articles.png',
  },
  {
    name: 'Articles Category',
    group: ['content'],
    widget: {
      type: 'articles-category',
      data: {},
      to: null,
    },
    image: 'assets/images/widgets/articles-category.PNG',
  },

  {
    name: 'Video with Text Widget',
    image: 'assets/images/widgets/image-with-video.PNG',
    group: ['banner', 'group'],
    widget: {
      type: 'widget-group',
      _id: new ObjectId().toHexString(),

      to: new TemplateOptionsVm({
        className: 'row bg-gradiant-accent' as any,
      }),
      data: {
        settings: {
          fullWidth: true,
          containerClass: 'bg-default',
          layout: ['row'],
          hasPadding: false,
        },
      },
      children: [
        new ComponentConfigVm({
          _id: new ObjectId().toHexString(),
          type: 'banner-w5',
          to: new TemplateOptionsVm({
            flex: TemplateOptionsVmFlex.Flex50,
          }),
          data: cloneDeep({...bannerWithVideoData}),
        }),
        new ComponentConfigVm({
          _id: new ObjectId().toHexString(),
          type: 'img-wtext-w4',
          to: new TemplateOptionsVm({
            flex: TemplateOptionsVmFlex.Flex50,
          }),
          data: cloneDeep({...textWidgetData}),
        }),
      ],
    },
  },
  {
    name: 'Image with Text Widget',
    image: 'assets/images/widgets/img-with-text4.PNG',
    group: ['banner', 'group'],
    widget: {
      type: 'widget-group',
      _id: new ObjectId().toHexString(),

      to: new TemplateOptionsVm({
        className: 'row bg-gradiant-accent' as any,
      }),
      data: {
        settings: {
          fullWidth: true,
          containerClass: 'bg-default',
          layout: ['row'],
          hasPadding: false,
        },
      },
      children: [
        new ComponentConfigVm({
          _id: new ObjectId().toHexString(),
          type: 'banner-w5',
          to: new TemplateOptionsVm({
            flex: TemplateOptionsVmFlex.Flex50,
          }),
          data: cloneDeep({...bannerWithImageData}),
        }),
        new ComponentConfigVm({
          _id: new ObjectId().toHexString(),
          type: 'img-wtext-w4',
          to: new TemplateOptionsVm({
            flex: TemplateOptionsVmFlex.Flex50,
          }),
          data: cloneDeep({...textWidgetData}),
        }),
      ],
    },
  },

  {
    name: 'Simple Text Widget',
    group: ['banner', 'content'],
    widget: {
      _id: new ObjectId().toHexString(),
      type: 'img-wtext-w4',
      data: cloneDeep({
        ...textWidgetData,
        settings: {
          ...textWidgetData.settings,
          showActionBtn: false,
        },
      }),
    },
    image: 'assets/images/widgets/img-with-text5.PNG',
  },
  {
    name: 'Configurable Floating Header',
    group: ['header'],
    widget: {
      type: 'header-w1',
      data: {
        settings: {
          type: 'floating',
        },
      },
      to: null,
    },
    image: 'assets/images/widgets/header-1.PNG',
  },
  {
    name: 'Header For CMS Pages Header',
    group: ['header'],
    widget: {
      type: 'header-w2',
      data: {
        settings: {
          type: 'fixed',
        },
      },
      to: null,
    },
    image: 'assets/images/widgets/header-2.PNG',
  },
  // Footer Widgets
  {
    name: 'Footer V1',
    group: ['footer'],
    widget: {
      type: 'footer-w2',
      data: {},
    },
    image: 'assets/images/widgets/footer-2.PNG',
  },
  {
    name: 'Footer V2',
    group: ['footer'],
    widget: {
      type: 'footer-w1',
      data: {},
    },
    image: 'assets/images/widgets/footer-1.PNG',
  },
  {
    name: 'Signup Widget',
    image: 'assets/images/widgets/widget-group-1.PNG',
    group: ['questions'],
    widget: {
      type: 'signup-w1',
      to: new TemplateOptionsVm({
        flex: TemplateOptionsVmFlex.Flex80,
      }),
      data: {},
    },
  },
  {
    name: 'Reset Widget',
    image: 'assets/images/widgets/widget-group-1.PNG',
    group: ['questions'],
    widget: {
      type: 'reset-w1',
      to: new TemplateOptionsVm({
        flex: TemplateOptionsVmFlex.Flex80,
      }),
      data: {},
    },
  },
  {
    name: 'Verify Email',
    image: 'assets/images/widgets/verify-email.PNG',
    group: ['questions'],
    widget: {
      type: 'verify-w1',
      to: new TemplateOptionsVm({
        flex: TemplateOptionsVmFlex.Flex80,
      }),
      data: {},
    },
  },

  // {
  //   name: 'Google Map',
  //   image: 'assets/images/widgets/google-map.PNG',
  //   group: ['banner'],
  //   widget: {
  //     type: 'gmap-w1',
  //     to: new TemplateOptionsVm({
  //       flex: TemplateOptionsVmFlex.Flex80,
  //     }),
  //     data: {},
  //   },
  // },

  // {
  //   name: 'Configurable Floating Header',
  //   group: ['header'],
  //   widget: {
  //     type: 'header-w1',
  //     data: {
  //       settings: {
  //         type: 'floating',
  //       },
  //     },
  //     to: null,
  //   },
  //   image: 'assets/images/widgets/header-1.png',
  // },
  // {
  //   name: 'Header For CMS Pages Header',
  //   group: ['header'],
  //   widget: {
  //     type: 'header-w2',
  //     data: {
  //       settings: {
  //         type: 'fixed',
  //       },
  //     },
  //     to: null,
  //   },
  //   image: 'assets/images/widgets/header-1.png',
  // },
  // {
  //   name: 'Fixed Header Light Background',
  //   group: ['header'],
  //   widget: {
  //     type: 'header-w2',
  //     data: {},
  //     to: null,
  //   },
  //   image: 'assets/images/widgets/header-2.png',
  // },
];
export const floatItems = [
  {id: 'all', name: 'All Widgets', icon: 'all_out'},
  {id: 'banner', name: 'Banner Widgets', icon: 'ballot'},
  {id: 'header', name: 'Header Widgets', icon: 'line_style'},
  {id: 'cta', name: 'CTA Widgets', icon: 'panorama'},
  {id: 'content', name: 'Content Widgets', icon: 'line_style'},
  {id: 'carousel', name: 'Carousel Widgets', icon: 'panorama'},
  {id: 'footer', name: 'Footer Widgets', icon: 'gradient'},
  {id: 'forms', name: 'Forms Widgets', icon: 'line_style'},
  {id: 'misc', name: 'Other Widgets', icon: 'ballot'},
];
