import {Injectable, PLATFORM_ID, Inject} from '@angular/core';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {tap, switchMap, catchError, take} from 'rxjs/operators';
import {
  UserVm,
  UserApiControllerClient,
  LoginVm,
  RegisterVm,
  LoginResponseVm,
  Roles,
  BodyEmailVm,
  ResetPassVm,
  VerifyEmailVm,
} from '../client-api';
import {isPlatformServer} from '@angular/common';
import {ReturnUrlService} from '../returnUrl/returnUrl.service';
import {Router} from '@angular/router';
import {ArrayUtils} from '@ecommerce/utils/array.extensions';
import {EditorConfigService} from '@ecommerce/ui-components/modules/dynamic-page/providers/editor-config.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: BehaviorSubject<UserVm> = new BehaviorSubject(null);
  private roles: BehaviorSubject<string[]> = new BehaviorSubject(null);
  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isVerified: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get rolesValue(): string[] {
    return this.roles.getValue() || [];
  }

  get rolesChanges(): Observable<string[]> {
    return this.roles;
  }

  constructor(
    private readonly _userService: UserApiControllerClient,
    private readonly _returnUrlService: ReturnUrlService,
    private readonly _router: Router,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    // Check User Status on Startup
  }
  init() {
    if (isPlatformServer(this.platformId)) return;
    this.checkStatus();
  }

  login(loginCred: LoginVm): Observable<LoginResponseVm> {
    return this._userService.login(loginCred).pipe(
      tap(({token, user}: any) => {
        localStorage.setItem('token', token);
        this.setUserObservers(user);
        if (token) {
          this._router.navigateByUrl(this._returnUrlService.url);
          this._returnUrlService.reset();
        }
      })
    );
  }
  socialLoginSuccess(token) {
    localStorage.setItem('token', token);
    return this._getCurrentUserAndRolesRx().pipe(
      take(1),
      tap((_) => {
        window.location.href = '/skill-progress';
      }),
      catchError((e) => of(null))
    );
  }
  loginWithGoogle() {
    // return this._userService.googleLogin();
    // TODO: Aun to implement logic to change the url based on current domain.
    window.open('http://localhost:5001/api/users/google', '_self');
  }

  register(user: RegisterVm): Observable<UserVm> {
    return this._userService.register(user).pipe(
      // switchMap((__) =>
      //   this._userService.login(
      //     new LoginVm({email: user.email, password: user.password})
      //   )
      // ),
      tap(({token, _user}: any) => {
        localStorage.setItem('token', token);
        // this.setUsernRoles(_user);
        this.checkStatus(); // To Fix the bug of login after registering new account.
        // if (token) {
        //   this._router.navigateByUrl(this._returnUrlService.url);
        //   this._returnUrlService.reset();
        // }
      })
    );
  }

  logout() {
    this.isLoggedIn.next(false);
    this.isActive.next(false);
    localStorage.removeItem('token');
    this.user.next(null);
    this.roles.next(null);
    this._router.navigateByUrl('/');
  }

  checkStatus() {
    if (isPlatformServer(this.platformId)) return;

    if (localStorage.getItem('token')) {
      this.isLoggedIn.next(true);
    } else {
      this.isLoggedIn.next(false);
    }

    this._getCurrentUserAndRolesRx()
      .pipe(
        take(1),
        catchError((e) => of(null))
      )
      .subscribe();
  }
  sendForgotEmail(emailVm: BodyEmailVm): Observable<boolean> {
    return this._userService.sendForgot(emailVm);
  }
  resetPassword(resetVm: ResetPassVm): Observable<boolean> {
    return this._userService.resetPassword(resetVm);
  }
  verifyEmail(verifyVm: VerifyEmailVm): Observable<boolean> {
    return this._userService.verifyEmail(verifyVm);
  }
  _getCurrentUserAndRolesRx(): Observable<UserVm> {
    if (isPlatformServer(this.platformId)) return of(null);
    try {
      return this._userService.getMyProfile().pipe(
        catchError((e) => of(null)),
        tap((user: UserVm) => this.setUserObservers(user))
      );
    } catch (e) {
      console.log(e);
    }
  }
  setUserObservers(user: UserVm) {
    this.isLoggedIn.next(!!user);
    this.user.next(user);
    this.roles.next(user && user.roles);
    this.isActive.next(user && !user.deactivated);
    this.isVerified.next(this._checkIsVerified(user));
  }

  private _checkIsVerified(user: UserVm): boolean {
    return user && user.verified && user.verified.email;
  }

  // Roles Helpers

  /**
   * Return true if the Current user is an Admin
   *
   * @readonly
   * @type {boolean}
   * @memberof AuthService
   */
  get isAdmin(): boolean {
    return this.rolesValue.includes(Roles.Admin);
  }

  /**
   * Return True if the Current user is an Admin or CSR
   *
   * @readonly
   * @type {boolean}
   * @memberof AuthService
   */
  get isAdminOrCsr(): boolean {
    return ArrayUtils.includesAny(this.rolesValue, [Roles.Admin, Roles.Csr]);
  }

  /**
   * Checks if Current User has Any of the Roles Provided
   *
   * @date 2020-08-20
   * @param {Array<Roles>} roles
   * @returns
   * @memberof AuthService
   */
  checkAnyOfRoles(roles: Array<Roles>) {
    return ArrayUtils.includesAny(this.rolesValue, roles);
  }

  /**
   * Checks if Current User has All of the Roles Provided
   *
   * @date 2020-08-20
   * @param {Array<Roles>} roles
   * @returns
   * @memberof AuthService
   */
  checkAllOfRoles(roles: Array<Roles>) {
    return ArrayUtils.includesAll(this.rolesValue, roles);
  }
}
