import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  EventEmitter,
  Output,
  OnDestroy,
  AfterContentChecked,
  TemplateRef,
  ViewContainerRef,
  Inject,
  PLATFORM_ID,
  OnInit,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { fromEvent, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { FuseConfigService } from '@ecommerce/ui-services/fuse/config.service';
import { takeUntil, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[dirFixOnTop]',
})
export class FixedNavbarDirective implements OnDestroy {
  listener: () => void;
  clientWidth: number;
  @Input('mw') maintainWidth = false;
  @Input() height: number;
  @Input() fixedClass = 'fixed-navbar';
  @Input() bodyClass = 'fix-navbar-ontop';
  @Input() contentClass = 'fix-navbar-ontop';
  unSubAll: Subject<any> = new Subject();
  // @Input() scrollContainer: any;
  constructor(
    private renderer: Renderer2,
    private element: ElementRef,
    private fuseConfig: FuseConfigService,
    @Inject(PLATFORM_ID) private _platformId: string
  ) {
    // Set the defaults
    if (isPlatformBrowser(this._platformId)) {
      // requestAnimationFrame(this.render.bind(this));
      this.render();
    }
  }
  render() {
    fromEvent(window, 'scroll')
      .pipe(
        takeUntil(this.unSubAll),
        switchMap((_) => this.fuseConfig.getConfig())
      )
      .subscribe((config) => {
        if (
          window.scrollY > (this.height || this.element.nativeElement.offsetTop)
        ) {
          if (
            config.layout.navbar.position === 'top' ||
            config.layout.style === 'horizontal-layout-1'
          )
            this.renderer.addClass(
              document.getElementsByTagName('content')[0],
              this.contentClass
            );

          this.renderer.addClass(document.body, this.bodyClass);
          this.fixedClass
            .split(' ')
            .forEach((c) =>
              this.renderer.addClass(this.element.nativeElement, c)
            );
          this.renderer.setStyle(
            this.element.nativeElement,
            'width',
            `${this.clientWidth}px`
          );
        } else {
          this.renderer.removeClass(
            document.getElementsByTagName('content')[0],
            this.contentClass
          );
          this.renderer.removeClass(document.body, this.bodyClass);
          this.fixedClass
            .split(' ')
            .forEach((c) =>
              this.renderer.removeClass(this.element.nativeElement, c)
            );
          // this.renderer.removeClass(this.element.nativeElement, this.fixedClass);
        }
      });
  }
  ngOnDestroy() {
    if (isPlatformBrowser(this._platformId)) {
      this.renderer.removeClass(
        document.getElementsByTagName('content')[0],
        this.contentClass
      );
      this.renderer.removeClass(document.body, this.bodyClass);
      this.fixedClass
        .split(' ')
        .forEach((c) =>
          this.renderer.removeClass(this.element.nativeElement, c)
        );
    }
    this.unSubAll.next();
    this.unSubAll.complete();
  }
}

// @Directive({
//   selector: '[wow]',
// })
// // tslint:disable-next-line:directive-class-suffix
// export class FOCWOWDIRECTIVE implements OnDestroy {
//   private nativeElement: Node;
//   @Input() offset: number = 0;
//   @Input() animationClass: string;
//   @Input() repeat: boolean = false;
//   subscription: Subscription;
//   private get scroller(): Element {
//     if (isPlatformBrowser(this._platformId)) return document.scrollingElement;
//   }
//   constructor(
//     private renderer: Renderer2,
//     private element: ElementRef,
//     @Inject(PLATFORM_ID) private _platformId: string
//   ) {
//     // Set the defaults
//     if (isPlatformBrowser(this._platformId)) {
//       // requestAnimationFrame(this.render.bind(this));
//       this.render();
//     }

//     // this.renderer.destroy();
//   }
//   render() {
//     this.subscription = <Subscription>fromEvent(window, 'scroll').subscribe(
//       () => {
//         if (
//           !(
//             this.scroller.scrollTop + this.scroller.clientHeight >=
//             this.element.nativeElement.offsetTop + this.offset
//           ) &&
//           this.repeat
//         )
//           this.renderer.removeClass(
//             this.element.nativeElement,
//             this.animationClass
//           );
//         else if (
//           this.scroller.scrollTop + this.scroller.clientHeight >=
//           this.element.nativeElement.offsetTop + this.offset
//         ) {
//           this.renderer.addClass(
//             this.element.nativeElement,
//             this.animationClass
//           );
//         }
//       }
//     );
//   }

//   ngOnDestroy() {
//     this.subscription && this.subscription.unsubscribe();
//   }
// }

// @Directive({
//   // tslint:disable-next-line:directive-selector
//   selector: '[load-below-fold]',
// })
// // tslint:disable-next-line:directive-class-suffix
// export class LoadBelowTheFold implements OnDestroy {
//   @Input() ranOnce: boolean = false;
//   private nativeElement: Node;
//   @Input() offset: number = 0;
//   @Input() animationClass: string;
//   // tslint:disable-next-line:no-output-rename
//   @Output('onChanges') onScrollReach: EventEmitter<any> = new EventEmitter();
//   subscription: Subscription;
//   private get scroller(): Element {
//     if (isPlatformBrowser(this._platformId)) return document.scrollingElement;
//   }
//   constructor(
//     private renderer: Renderer2,
//     private element: ElementRef,
//     @Inject(PLATFORM_ID) private _platformId: string
//   ) {
//     // Set the defaults
//     if (isPlatformBrowser(this._platformId)) {
//       this.subscription = <Subscription>fromEvent(window, 'scroll')
//         .filter(
//           (__) =>
//             this.scroller.scrollTop + this.scroller.clientHeight >=
//               this.element.nativeElement.offsetTop + this.offset &&
//             !this.ranOnce
//         )
//         .do((__) => {
//           //  requestAnimationFrame(this.render.bind(this));
//           this.render();
//         })
//         .subscribe();
//     }
//   }
//   render() {
//     this.ranOnce = true;
//     this.onScrollReach.emit();
//     if (this.animationClass)
//       this.renderer.addClass(this.element.nativeElement, this.animationClass);
//   }
//   ngOnDestroy() {
//     this.subscription && this.subscription.unsubscribe();
//   }
// }

// @Directive({
//   selector: '[showBelowFold]',
// })
// export class ShowBelowTheFold implements AfterContentChecked, OnDestroy {
//   isCreated: boolean;
//   @Input() offset: number = 0;
//   subscription: Subscription;
//   private get scroller(): Element {
//     if (isPlatformBrowser(this._platformId)) return document.scrollingElement;
//   }
//   /**
//    * Constructor
//    *
//    * @param {ElementRef} _elementRef
//    * @param {TemplateRef<any>} _templateRef
//    * @param {ViewContainerRef} _viewContainerRef
//    */
//   constructor(
//     private _elementRef: ElementRef,
//     private _templateRef: TemplateRef<any>,
//     private _viewContainerRef: ViewContainerRef,
//     private renderer: Renderer2,
//     @Inject(PLATFORM_ID) private _platformId: string
//   ) {
//     // Set the defaults
//     if (isPlatformBrowser(this._platformId)) {
//       this.subscription = <Subscription>fromEvent(window, 'scroll')
//         .filter(
//           (__) =>
//             this.scroller.scrollTop + this.scroller.clientHeight >=
//             this._elementRef.nativeElement.parentElement.offsetTop + this.offset
//         )
//         .filter((__) => !this.isCreated)
//         .debounceTime(10)
//         .do((__) =>
//           this._viewContainerRef.createEmbeddedView(this._templateRef)
//         )
//         .subscribe((__) => (this.isCreated = true));
//     }
//   }

//   // -----------------------------------------------------------------------------------------------------
//   // @ Lifecycle hooks
//   // -----------------------------------------------------------------------------------------------------

//   /**
//    * After content checked
//    */
//   ngAfterContentChecked(): void {
//     if (!this.isCreated) {
//       this._viewContainerRef.clear();
//     }
//   }
//   ngOnDestroy() {
//     this.subscription && this.subscription.unsubscribe();
//   }
// }

// @Directive({
//   selector: '[load-more]',
// })
// export class LoadMoreDirective implements OnDestroy, OnInit {
//   @Input() offset: number = 0;
//   @Input() loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
//   @Output() onLoad: EventEmitter<any> = new EventEmitter();
//   @Input() container: HTMLElement;
//   subscription: Subscription;

//   private get topHeight() {
//     if (this.container) {
//       return {
//         top: this.container.scrollTop,
//         height: this.container.scrollHeight - this.container.clientHeight,
//       };
//     }
//     if (
//       this._configService.getConfigValue().layout.style === 'vertical-layout-1'
//     ) {
//       const container = <HTMLElement>document.getElementById('container-3');
//       return {
//         top: container.scrollTop,
//         height: container.scrollHeight - container.clientHeight,
//       };
//     }

//     return {
//       top: window.screenTop,
//       height: window.scrollY - window.innerHeight,
//     };
//   }

//   private get scroller(): any {
//     if (this.container) {
//       return this.container;
//     }
//     if (isPlatformBrowser(this._platformId)) {
//       if (
//         this._configService.getConfigValue().layout.style ===
//         'vertical-layout-1'
//       ) {
//         const container = <HTMLElement>document.getElementById('container-3');
//         return container;
//       }

//       return window;
//     }
//   }
//   /**
//    * Constructor
//    *
//    * @param {ElementRef} _elementRef
//    *
//    */
//   constructor(
//     private _elementRef: ElementRef,
//     private renderer: Renderer2,
//     private _configService: FuseConfigService,
//     @Inject(PLATFORM_ID) private _platformId: string
//   ) {
//     // Set the defaults
//   }
//   ngOnInit() {
//     if (isPlatformBrowser(this._platformId)) {
//       this.subscription = fromEvent(this.scroller, 'scroll')
//         .filter((__) => !this.loading.value)
//         .filter(
//           (__) => this.topHeight.top + 1 >= this.topHeight.height - this.offset
//         )
//         .debounceTime(150)
//         .do((__) => this.onLoad.emit())
//         .subscribe();
//     }
//   }
//   ngOnDestroy() {
//     this.subscription && this.subscription.unsubscribe();
//   }
// }
