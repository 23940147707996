import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionBarComponent } from './action-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ImageUploaderModule } from '../image-uploader/image-uploader.module';
import { AddWidgetModalModule } from '../modals/add-widget-modal/add-widget-modal.module';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule( {
  declarations: [ ActionBarComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    ImageUploaderModule,
    AddWidgetModalModule,
    MatDialogModule
  ],
  exports: [ ActionBarComponent, MatIconModule, MatButtonModule ]
} )
export class ActionBarModule { }
