import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule} from '@ngx-translate/core';
// import { PrebootModule } from 'preboot';

import {FuseCsModule} from '@fuse-cs/fuse.module';
import {LayoutModule} from '@fuse-cs/layout/layout.module';

import {fuseConfig as config} from './fuse-config';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {navigation} from './navigation/navigation';
import {DynamicPageModule} from '@ecommerce/ui-components/modules/dynamic-page/dynamic-page.module';
import {API_BASE_URL} from '@ecommerce/ui-services/client-api/client-api.service';

import {BrowserStateInterceptorProvider} from '@ecommerce/ui-components/interceptors/browser.state.interceptor';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {HttpErrorInterceptorProvider} from '@ecommerce/ui-features/error-pages/error-interceptors/not-found.error.interceptor';
import {ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyMaterialModule} from '@ngx-formly/material';
import {formlyDefaultConfig} from '@ecommerce/ui-components/modules/formly-fields/formly.default.config';
import {MatButtonModule} from '@angular/material/button';
import {NgSelectModule} from '@ng-select/ng-select';
import {LoadingService} from '@ecommerce/ui-services/loading/loading.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatFabMenuModule} from '@angular-material-extensions/fab-menu';
import {AuthService} from '@ecommerce/ui-services/auth/auth.service';
import {AuthInterceptorProvider} from '@ecommerce/ui-services/auth/auth-interceptors.service';
import { environment } from '@ecommerce/environments/client/environment';
import {
  OnlyAdminUserGuard,
  OnlyLoggedInUsersGuard,
  OnlySPUsersGuard,
  OnlyCSRUsersGuard,
} from '@ecommerce/ui-services/guards/auth.guards';
import {ReturnUrlService} from '@ecommerce/ui-services/returnUrl/returnUrl.service';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
declare var process;

export function baseUrl(): string {
  if (typeof window !== 'undefined') {
    return window.location.origin;
  } else if (typeof process !== 'undefined'){
    return  `${
      process.env.API_GATEWAY_HOST
        ? process.env.API_GATEWAY_HOST + ':' + process.env.API_GATEWAY_PORT
        : 'http://client-api:5001'
    }`;
  }
}

const globalSettings: RecaptchaSettings = { siteKey: environment.recaptcha.siteKey, theme: 'light' };


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    TransferHttpCacheModule,
    // PrebootModule.withConfig({ appRoot: 'comp-app-root' }),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    // Fuse modules
    FuseCsModule.forRoot({
      config,
      navigation,
      configName: 'kai:web-client:ssr',
    }),
    LayoutModule,
    MatButtonModule,
    // Mendatory Material Module
    MatSnackBarModule,

    //Formly
    ReactiveFormsModule,
    FormlyModule.forRoot(formlyDefaultConfig),
    FormlyMaterialModule,
    NgSelectModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    // App modules
    DynamicPageModule.forRoot({}),
    MatFabMenuModule,
    ServiceWorkerModule.register('svc-wrkr.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: API_BASE_URL, useFactory: baseUrl},
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalSettings,
    },
    // Manual
    AuthInterceptorProvider,
    HttpErrorInterceptorProvider,
    BrowserStateInterceptorProvider,
    LoadingService,

    AuthService,
    OnlyAdminUserGuard,
    OnlyLoggedInUsersGuard,
    OnlySPUsersGuard,
    OnlyCSRUsersGuard,
    ReturnUrlService,

    {provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks},
  ],
})
export class AppModule {}
