import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddWidgetModalComponent } from './add-widget-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FloatBarModule } from '../../float-bar/float-bar.module';
import { UiDirectivesModule } from '@ecommerce/ui-directives/ui-directives.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';



@NgModule( {
  declarations: [ AddWidgetModalComponent ],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatInputModule,
    MatSidenavModule,
    FloatBarModule,
    UiDirectivesModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [ AddWidgetModalComponent ],
  exports: [ AddWidgetModalComponent ]
} )
export class AddWidgetModalModule { }
