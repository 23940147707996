import { NgModule } from '@angular/core';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from '@fuse-cs/directives/directives';
import { FusePipesModule } from '@fuse-cs/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    // FormsModule,
    // ReactiveFormsModule,
    FlexLayoutModule,

    FuseDirectivesModule,
    FusePipesModule,
  ],
  exports: [
    CommonModule,
    // FormsModule,
    // ReactiveFormsModule,

    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
  ],
})
export class FuseCsSharedModule {}
