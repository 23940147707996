import { Component, ChangeDetectionStrategy, ViewChild, NgModule, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MatSlider, MatSliderModule } from '@angular/material/slider';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component( {
  selector: 'formly-field-mat-feedback',
  styles: [
    '.formly-feedback-wrapper {margin-bottom: 1.5rem;}',
  ],
  template: `
    <div class="formly-feed-wrapper">
      <div class="row align-items-center">
        <label class="feed-label" for="id">
          {{ to.label }}
        </label>
        <button class="feed-btn" mat-icon-button (click)="formControl?.setValue(true)">
          <i class="icon-thumb-up" [ngClass]="{'accent-fg': formControl.value}"></i>
        </button>
        <button class="feed-btn" mat-icon-button (click)="formControl?.setValue(false)">
          <i class="icon-thumb-down" [ngClass]="{'accent-fg': formControl.value === false}"></i>
        </button>
      </div>
      <label for="id" class="font-size-12 grey-600-fg">
        {{ to.description }}
      </label>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class FormlyFieldFeedback extends FieldType implements OnInit {
  ngOnInit (): void {
  }
}

@NgModule( {
  imports: [ CommonModule, ReactiveFormsModule, MatIconModule, MatButtonModule ],
  exports: [],
  declarations: [ FormlyFieldFeedback ],
  providers: [],
} )
export class FormlyFieldFeedbackModule { }
