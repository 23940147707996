import { FuseNavigation } from '@ecommerce/interfaces/fuse/types/fuse-navigation';

export const navigation: FuseNavigation[] = [
  {
    id: 'applications',
    title: 'Applications',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    children: [
      {
        id: 'home',
        title: 'Home',
        translate: 'NAV.HOME.TITLE',
        type: 'item',
        icon: 'home',
        url: '/home',
      },
      {
        id: 'articles',
        title: 'Help',
        translate: 'NAV.ARTICLES.TITLE',
        type: 'item',
        icon: 'speaker_notes',
        url: '/articles',
      },
      {
        id: 'about',
        title: 'About',
        translate: 'NAV.ABOUT.TITLE',
        type: 'item',
        icon: 'info',
        url: '/about',
      },
      {
        id: 'contact',
        title: 'Contact',
        translate: 'NAV.CONTACT.TITLE',
        type: 'item',
        icon: 'email',
        url: '/contact',
      },
    ],
  },
  {
    id: 'auth',
    title: 'Auth',
    translate: 'NAV.AUTH',
    type: 'group',
    url: 'auth',
    children: [
      {
        id: 'login',
        title: 'Login',
        translate: 'NAV.LOGIN.TITLE',
        type: 'item',
        icon: 'cloud_download',
        externalUrl: true,
        url: 'https://portal.kaiops.io',
        badge: {
          title: '25',
          translate: 'NAV.LOGIN.BADGE',
          bg: '#F44336',
          fg: '#FFFFFF',
        },
      },
    ],
  },
];
