<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->
<ng-container *ngIf="!!fuseConfig">
  <!-- HORIZONTAL LAYOUT 1 -->
  <ng-container *ngIf="fuseConfig?.layout.style === 'horizontal-layout-1'">
    <horizontal-layout-1></horizontal-layout-1>
  </ng-container>
  <!-- / HORIZONTAL LAYOUT 1 -->

  <!-- THEME OPTIONS PANEL -->
  <ng-container *ngIf="isAdmin">
    <button
      mat-icon-button
      class="warn mat-elevation-z2 theme-options-button"
      (click)="toggleSidebarOpen('themeOptionsPanel')"
    >
      <mat-icon>settings</mat-icon>
    </button>

    <fuse-sidebar
      name="themeOptionsPanel"
      class="theme-options-sidebar"
      position="right"
      [invisibleOverlay]="true"
    >
      <fuse-theme-options></fuse-theme-options>
    </fuse-sidebar>
  </ng-container>
  <!-- / THEME OPTIONS PANEL -->
</ng-container>
