<!-- <div class="formly-mat-boiler-autocomplete-wrapper"> -->
<ng-select
  class="width-280"
  [items]="opts | async"
  [placeholder]="to.label || to.placeholder"
  [bindLabel]="to.bindLabel"
  [bindValue]="to.bindValue || undefined"
  [addTag]="(to.addTag && to.addTag(to))|| false"
  [multiple]="to.multiple || false"
  [hideSelected]="true"
  [trackByFn]="to.trackByFn"
  [minTermLength]="to.minTermLength || 2"
  [loading]="to.loading"
  [typeToSearchText]="to.label"
  [typeahead]="to.typeahead$"
  [formControl]="fc"
  [disabled]="to.disabled"
  appendTo="body"
>
  <ng-container *ngIf="to.userTemplate">
     <ng-template ng-label-tmp let-user="item">
      <div fxLayout fxFlexAlign="center">
        <img class="avatar" [src]="user?.profile?.picture?.url || 'assets/images/avatars/profile.jpg'"/>
        <span class="text-wrap-1" fxFlex="90" style="line-height:40px">
          {{user.fullName}}
        </span>

      </div>
    </ng-template>
    <ng-template
      ng-option-tmp
      let-user="item"
      let-index="index"
      let-search="searchTerm"
    >
        <div class="card-text row align-center">
          <img
          class="avatar circle"
          [src]="user?.profile?.picture?.url || 'assets/images/avatars/profile.jpg'"
          />
          <div class="column" fxFlex="50">
            <span class="text-bold">{{user.fullName}}</span>
            <span class="grey-700-fg">
              {{user?.username}}
            </span>
          
          </div>
        </div>
    </ng-template>
  </ng-container>

  <ng-template ng-tag-tmp let-search="searchTerm">
    <b>Create New</b>: {{search}}
  </ng-template>
</ng-select>
<!-- </div> -->
