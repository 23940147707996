import {Component, OnInit, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {ImageUploaderModule} from '@ecommerce/ui-components/components/image-uploader/image-uploader.module';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'comp-image-upload',
  templateUrl: 'image-upload.component.html',
  styleUrls: ['image-upload.component.scss'],
})
export class FormlyImageUploadComponent extends FieldType {
  // Set Image and Call change event.
  onChange($event) {
    this.formControl.setValue($event);
    if(this.to.change)
      this.to.change(this.field, $event);
  }
}

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, ImageUploaderModule],
  exports: [],
  declarations: [FormlyImageUploadComponent],
  providers: [],
})
export class FormlyFieldImageUploadModule {}
