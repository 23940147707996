import {
  Injectable,
  OnDestroy,
  InjectionToken,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import {BehaviorSubject, Subject, Observable, of} from 'rxjs';
import {takeUntil, take, tap, catchError, map} from 'rxjs/operators';
import {FuseNavigation} from '@ecommerce/interfaces/fuse/types/fuse-navigation';
import {FuseConfig} from '@ecommerce/interfaces/fuse/types/fuse-config';
import {
  ConfigApiControllerClient,
  AdminConfigVm,
  UpdateGenericVm,
  AdminConfigCreateVm,
  ThemeConfigUpdateVm,
  ThemeOptionsConfigVm,
} from '../client-api';
import {isPlatformServer} from '@angular/common';

export const DEFAULT_SETTING = new InjectionToken('defaultSettings');

@Injectable({providedIn: 'root'})
export class SettingsApiService implements OnDestroy {
  private _navigation$: BehaviorSubject<FuseNavigation> = new BehaviorSubject(
    null
  );
  private _id$: BehaviorSubject<string> = new BehaviorSubject(null);
  private _theme$: BehaviorSubject<FuseConfig> = new BehaviorSubject(null);
  private _adminConfig$: BehaviorSubject<AdminConfigVm> = new BehaviorSubject(
    null
  );

  private unSubscribe: Subject<any> = new Subject();

  constructor(
    private readonly _adminConfigService: ConfigApiControllerClient,
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(DEFAULT_SETTING)
    private _settings: {
      navigation: FuseNavigation;
      config: FuseConfig;
      configName: string;
    }
  ) {
    // TODO: Implement Server Side Navigation
    this.navigation = this._settings.navigation;
    this.theme = this._settings.config;

    if (isPlatformServer(this.platformId)) return;
    this.getLatestSettings();

    // this.config = this._settings.config;
  }

  get navigation(): any {
    return this._navigation$.asObservable();
  }
  set navigation(navigation: any) {
    this._navigation$.next(navigation);
  }

  get themeChanges(): any {
    return this._theme$.asObservable();
  }
  set theme(theme: FuseConfig) {
    this._theme$.next(theme);
  }

  get configChanges(): Observable<AdminConfigVm> {
    return this._adminConfig$.asObservable();
  }
  set config(config: any) {
    this._adminConfig$.next(config);
  }

  get id() {
    return this._id$.value;
  }
  get idChanges() {
    return this._id$.asObservable();
  }
  getLatestSettings() {
    this._adminConfigService
      .getAdminConfig(this._settings.configName)
      .pipe(
        take(1),
        catchError((e) => {
          if (e && e.statusCode === 404) {
            const adminConfig = new AdminConfigCreateVm();
            adminConfig.init({
              name: this._settings.configName,
              themeOptions: this._settings.config,
              environmentConfig: {
                smtpUrl: 'smtps://server.com',
                company: {
                  address1: '12 Alpine Loft',
                  address2: 'New City Generation X',
                  country: 'Mars',
                  state: 'Tesla',
                  postcode: 'SPACEX12',
                  contactEmail: 'contactus@mars.com',
                  legalName: 'Mars Development Studio Ltd',
                  name: 'Mars Dev',
                  noreplyEmail: 'noreply@mars.com',
                  phone: 'X1 321 4451 3Z',
                  tradeNo: '1211',
                  taxNo: '3211',
                  about: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vel ex perspiciatis expedita! Quaerat omnis a voluptas sapiente non alias magnam, officiis vel dolorum autem eveniet quidem id accusamus commodi quia.',
                  logo: {
                    url: '/assets/images/logos/fuse.svg',
                  },
                },
                social: {
                  fb: {pageUrl: 'https://www.facebook.com/spacextechnologies'},
                  li: {pageUrl: 'https://www.linkedIn.com/spacextechnologies'},
                  ig: {pageUrl: 'https://www.instagram.com/spacextechnologies'},
                  tw: {pageUrl: 'https://www.twitter.com/spacextechnologies'},
                  yt: {pageUrl: 'https://www.youtube.com/spacextechnologies'},
                },
                stripConfig: {
                  pk:
                    'pk_test_51ImLr8JGqkcnHWENASjPfp3OgC2YsMNiffqxx3r3RGOIfpCpGFkVfnVPbke1WoaNpNW7tlqHGsOQmXWCrTsQVeyj00DzVq4utA',
                  sk:
                    'sk_test_51ImLr8JGqkcnHWENk3TC0iR4ya1BgGPiJLostSMrjIN5gf2SwdPk4Xkmeeb4VmwWlKSTsZcR74HzD3AM6nO2ctH400smq0fCKi',
                },
              },
            });
            return this._insert$(adminConfig);
          }
          return of(null);
        }),
        tap(this._setData)
      )
      .subscribe();
  }

  private _setData = (data: AdminConfigVm) => {
    if (!data) return;
    this._id$.next(data.id);
    this._adminConfig$.next(data);
    this._theme$.next(data.themeOptions as FuseConfig);
    // this._navigation$.next(data.navigation);
  };

  private _update$(modifier): Observable<AdminConfigVm> {
    return this._adminConfigService
      .update(new UpdateGenericVm({id: this.id, modifier}))
      .pipe(take(1), tap(this._setData));
  }
  private _insert$(doc: AdminConfigCreateVm) {
    return this._adminConfigService.create(doc);
  }

  updateGeneric$(modifier): Observable<AdminConfigVm> {
    return this._update$(modifier);
  }
  async updateGeneric(modifier): Promise<AdminConfigVm> {
    return this._update$(modifier).toPromise();
  }
  updateThemeOptions(config: FuseConfig): Observable<FuseConfig> {
    const themeOptions = new ThemeOptionsConfigVm();
    themeOptions.init(config);
    return this._adminConfigService
      .updateTheme(
        new ThemeConfigUpdateVm({
          id: this.id,
          themeOptions,
          resetRedis: this._settings.configName.includes('ssr'),
        })
      )
      .pipe(map((ac) => ac.themeOptions as FuseConfig));
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
