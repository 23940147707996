import {
  Component,
  OnInit,
  NgModule,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import {FieldArrayType, FieldType, FormlyModule} from '@ngx-formly/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule, FormControl} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-dynamic-field',
  templateUrl: 'dynamic-field.html',
  styleUrls: ['dynamic-field.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyDynamicFieldComponent extends FieldArrayType
  implements OnInit {

  ngOnInit() {
  }
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormlyModule
  ],
  exports: [],
  declarations: [FormlyDynamicFieldComponent],
  providers: [],
})
export class FormlyDynamicFieldModule {}
