// panel-wrapper.component.ts
import {Component, ViewChild, ViewContainerRef, NgModule} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { MatListModule } from "@angular/material/list";
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'formly-list-wrapper-panel',
  template: `
    <mat-list>
      <ng-container #fieldComponent></ng-container>
    </mat-list>
  `,
})
export class MatListWrapperComponent extends FieldWrapper {}

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatListModule],
  exports: [],
  declarations: [MatListWrapperComponent],
  providers: [],
})
class FormlyFielArrayToggleModule {}
