import { Component, OnInit, NgModule, Compiler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FieldType } from '@ngx-formly/core';
import { MatInputModule } from '@angular/material/input';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-field-autocomplete',
  templateUrl: 'formlyfield.autocomplete.html',
  styleUrls: ['formlyfield.autocomplete.scss'],
})
export class FormlyFieldAutocomplete extends FieldType implements OnInit {
  fc: any;
  opts;
  ngOnInit() {
    this.fc = this.formControl;
    this.opts = this.to.options;
  }
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
  ],
  exports: [FormlyFieldAutocomplete, MatAutocompleteModule],
  declarations: [FormlyFieldAutocomplete],
  providers: [],
})
export class FormlyFieldAutocompleteModule {}
