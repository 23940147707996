import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetActionbarComponent } from './widget-actionbar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AddWidgetModalModule } from '../modals/add-widget-modal/add-widget-modal.module';
import { EditImageModalModule } from '../modals/edit-image-modal/edit-image-modal.module';


@NgModule( {
  declarations: [ WidgetActionbarComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    AddWidgetModalModule,
    EditImageModalModule
  ],
  exports: [ WidgetActionbarComponent ]
} )
export class WidgetActionbarModule { }
