<div class="container">
  <div class="title text-center">Service Type?</div>
  <!-- <mat-divider></mat-divider> -->
  <div class="services-wrapper">
    <div routeTransformer class="services" *ngFor="let ser of services">
      <button class="service" (click)="selectService(ser)">
        <div
          [class]="'icon-svg ' + ser?.svgClass"
          aria-label="My icon"
          [inlineSVG]="'/assets/icons/svgs/web-icons.svg#' + ser?.svgId"
          [setSVGAttributes]="{
            class: '',
            viewBox: '0 0 100 100',
            width: 100,
            height: 100
          }"
        ></div>
        <a href="/requirement/{{ ser.link }}" class="name">
          {{ ser?.name }}
        </a>
      </button>
    </div>
  </div>
</div>
