import {Component, OnInit, NgModule, ViewEncapsulation} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatListModule} from '@angular/material/list';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-field-toggle-switch',
  encapsulation: ViewEncapsulation.None,
  template: `
    <mat-slide-toggle
      class="toggle"
      [ngClass]="to.class"
      [color]="to.color"
      [labelPosition]="to.labelPosition || 'after'"
      [formControl]="fc"
    >
      {{ to.label }}
    </mat-slide-toggle>
  `,
  styles: [
    '.toggle{min-width: 100%; height: 63.5px;}',
    '.mat-slide-toggle-content{flex: 1 1 100%;}',
    '.vertical label{flex-direction: column-reverse; justify-content: start; align-item: start}',
    '.vertical .mat-slide-toggle-content{flex: unset}',
  ],
})
export class FormlyFieldToggleSwitchComponent
  extends FieldType
  implements OnInit
{
  fc;
  opts;
  ngOnInit(): void {
    this.fc = this.formControl;
    this.opts = this.to.options;
  }
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatListModule,
  ],
  exports: [],
  declarations: [FormlyFieldToggleSwitchComponent],
  providers: [],
})
export class FormlyFielArrayToggleModule {}
