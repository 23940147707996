import {Component, NgModule} from '@angular/core';
import {FieldArrayType, FormlyModule} from '@ngx-formly/core';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'formly-repeat-section',
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index" class="row">
      <formly-field class="col" [field]="field"></formly-field>
    </div>
  `,
})
export class RepeatTypeComponent extends FieldArrayType {}

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormlyModule],
  exports: [RepeatTypeComponent],
  declarations: [RepeatTypeComponent],
  providers: [],
})
export class FormlyFieldRepeatTypeModule {}
