import { TransferState, makeStateKey } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class BrowserStateInterceptor implements HttpInterceptor {
  constructor(private transferState: TransferState) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.method !== 'GET') {
      return next.handle(req);
    }
    const url = new URL(req.url);
    const key = url.pathname + url.search;
    const stateKey = makeStateKey(key);
    const storedResponse: string = this.transferState.get<any>(stateKey, null);

    if (storedResponse) {
      const response = new HttpResponse({ body: storedResponse, status: 200 });
      this.transferState.remove(stateKey);
      return of(response);
    }

    return next.handle(req);
  }
}

export const BrowserStateInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: BrowserStateInterceptor,
  multi: true,
};
