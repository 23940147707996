import { NgModule } from '@angular/core';

import { FuseCsSharedModule } from '@fuse-cs/shared.module';

import { NavbarComponent } from '../../components/navbar/navbar.component';
import { NavbarHorizontalStyle1Module } from '../../components/navbar/horizontal/style-1/style-1.module';
import { NavbarVerticalStyle1Module } from '../../components/navbar/vertical/style-1/style-1.module';
import { NavbarVerticalStyle2Module } from '../../components/navbar/vertical/style-2/style-2.module';

@NgModule({
  declarations: [NavbarComponent],
  imports: [
    FuseCsSharedModule,

    NavbarHorizontalStyle1Module,
    NavbarVerticalStyle1Module,
    NavbarVerticalStyle2Module,
  ],
  exports: [NavbarComponent],
})
export class NavbarModule {}
