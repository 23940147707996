export const environment = {
  production: true,
  portalLink: 'https://portal.kaiops.io',
  invoiceOverDuesIn: 3,
  onlyVerifiedLogins: false,
  maxLeadPurchasers: 3,
  minConfidense: 0.5,
  enableVat: true,
  vat: 0.2,
  currency: 'usd',
  // WEB_PUSH

  pollInterval: 30 * 1000, // 30 Sec
  pollCounts: 30,

  VAPID_PUBLIC_KEY:
    'BFnu5582e9zZYGK04LDUUM-QfZtYsbgADK7wLpbOABKgNLwrBlgqVaTXmjdVZ4JBgXYRwQ8680pmHVoa1ffXGEc',

  //TODO: Change this to live once ready for deployment.
  stripePk:
    'pk_test_51Mb6w2HEUlZtPCnIXZIQecLToYA0tVo4gWCFtoJruNAwSVHmlxsj5kQWAhxlzbHtlnlvCxioeZO0jQQf1kYv6NM400T9B1w0Ej',
  stripePkReview:
    'pk_test_51ImLr8JGqkcnHWENASjPfp3OgC2YsMNiffqxx3r3RGOIfpCpGFkVfnVPbke1WoaNpNW7tlqHGsOQmXWCrTsQVeyj00DzVq4utA',
  recaptcha: {
    siteKey: '6Lcb81gaAAAAAPNrh_Z6jLpNvouUS_UihYf4wS6-',
  },
  gmapApi: 'AIzaSyBSxktcGWDlqpoPbXugnw1x78Y6T6LH1w0',
  api: 'http://client-api:5000',
  host: 'www.goboilerquotes.com',
};
