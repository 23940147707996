import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';

import { FuseSidebarModule } from '@fuse-cs/components/sidebar/sidebar.module';
import { FuseCsSharedModule } from '@fuse-cs/shared.module';

import { ContentModule } from '../../components/content/content.module';
import { FooterModule } from '../../components/footer/footer.module';
import { NavbarModule } from '../../components/navbar/navbar.module';
import { ToolbarModule } from '../../components/toolbar/toolbar.module';

import { HorizontalLayout1Component } from '../../horizontal/layout-1/layout-1.component';

@NgModule({
  declarations: [HorizontalLayout1Component],
  imports: [
    MatSidenavModule,

    FuseCsSharedModule,
    FuseSidebarModule,

    ContentModule,
    FooterModule,
    NavbarModule,
    ToolbarModule,
  ],
  exports: [HorizontalLayout1Component],
})
export class HorizontalLayout1Module {}
