import {
  Injectable,
  OnDestroy,
  InjectionToken,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import {BehaviorSubject, Subject, Observable, of} from 'rxjs';
import {take, tap, catchError} from 'rxjs/operators';
import {
  ConfigApiControllerClient,
  AdminConfigVm,
  UpdateGenericVm,
  AdminConfigCreateVm,
  ImageBasicVm,
  SocialConfigModelVm,
  ProtectedEnvConfigModelVm,
  EcommerceConfigModelVm,
  ProtectedTPConfigModelVm,
  CompanyConfigModelVm,
  EnvironmentConfigModelVm,
} from '../client-api';
import {isPlatformServer} from '@angular/common';
import {EditorConfigService} from '@ecommerce/ui-components/modules/dynamic-page/providers/editor-config.service';

export const DEFAULT_SETTING = new InjectionToken('defaultSettings');

@Injectable({providedIn: 'root'})
export class EnvVariableApiService implements OnDestroy {
  private _id$: BehaviorSubject<string> = new BehaviorSubject(null);
  private _adminConfig$: BehaviorSubject<AdminConfigVm> = new BehaviorSubject(
    null
  );

  private unSubscribe: Subject<any> = new Subject();

  constructor(
    private readonly _adminConfigService: ConfigApiControllerClient,
    private readonly _cmsService: EditorConfigService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    // if (isPlatformServer(this.platformId)) return;
    // check if CMS then getLatestSettingsAdmin elese getLatestSettingsAdmin
    if (this._cmsService.isCMS && !isPlatformServer(this.platformId)) {
      this.getLatestPrivateSettings();
    } else this.getLatestPublicSettings();
  }

  get configChanges(): Observable<AdminConfigVm> {
    if (isPlatformServer(this.platformId)) {
      return this._adminConfigService.getConfig('master');
    }
    return this._adminConfig$.asObservable();
  }
  get config(): AdminConfigVm {
    return this._adminConfig$.value;
  }
  set config(config: AdminConfigVm) {
    this._adminConfig$.next(config);
  }

  get id() {
    return this._id$.value;
  }
  get idChanges() {
    return this._id$.asObservable();
  }
  getLatestPublicSettings() {
    this._adminConfigService
      .getConfig('master')
      .pipe(take(1), tap(this._setData))
      .subscribe();
  }
  getLatestPrivateSettings() {
    this._adminConfigService
      .getAdminConfig('master')
      .pipe(
        take(1),
        catchError((e) => {
          if (e && e.statusCode === 404) {
            const adminConfig = new AdminConfigCreateVm({
              name: 'master',
              environmentConfig: new EnvironmentConfigModelVm({
                company: new CompanyConfigModelVm({
                  address1: '12 Alpine Loft',
                  address2: 'New City Generation X',
                  country: 'Mars',
                  state: 'Tesla',
                  postcode: 'SPACEX12',
                  contactEmail: 'contactus@mars.com',
                  legalName: 'Mars Development Studio Ltd',
                  name: 'Mars Dev',
                  noreplyEmail: 'noreply@mars.com',
                  phone: 'X1 321 4451 3Z',
                  tradeNo: '1211',
                  taxNo: '3211',
                  about:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vel ex perspiciatis expedita! Quaerat omnis a voluptas sapiente non alias magnam, officiis vel dolorum autem eveniet quidem id accusamus commodi quia.',
                  logo: new ImageBasicVm({
                    url: '/assets/images/logos/fuse.svg',
                    id: '',
                  }),
                }),
                social: new SocialConfigModelVm({
                  fb: {pageUrl: 'https://www.facebook.com/spacextechnologies'},
                  li: {pageUrl: 'https://www.linkedIn.com/spacextechnologies'},
                  ig: {pageUrl: 'https://www.instagram.com/spacextechnologies'},
                  tw: {pageUrl: 'https://www.twitter.com/spacextechnologies'},
                  yt: {pageUrl: 'https://www.youtube.com/spacextechnologies'},
                }),
              }),
              protectedEnvConfig: new ProtectedEnvConfigModelVm({
                apiTimeout: 10000, // 10 Seconds
                maxFetchCount: 10,
                protectedThirdPartyIntKeys: new ProtectedTPConfigModelVm({}),
                ecommerceSettings: new EcommerceConfigModelVm({
                  enableVat: false,
                }),
              }),
            });
            return this._insert$(adminConfig);
          }
          return of(null);
        }),
        tap(this._setData)
      )
      .subscribe();
  }

  private _setData = (data: AdminConfigVm) => {
    if (!data) return;
    this._id$.next(data.id);
    this._adminConfig$.next(data);
  };

  private _update$(modifier): Observable<AdminConfigVm> {
    return this._adminConfigService
      .update(new UpdateGenericVm({id: this.id, modifier}))
      .pipe(take(1), tap(this._setData));
  }
  private _insert$(doc: AdminConfigCreateVm) {
    return this._adminConfigService.create(doc);
  }

  updateGeneric$(modifier): Observable<AdminConfigVm> {
    return this._update$(modifier);
  }
  async updateGeneric(modifier): Promise<AdminConfigVm> {
    return this._update$(modifier).toPromise();
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
