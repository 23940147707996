import { Component, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse-cs/animations';

@Component({
  selector: 'content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ContentComponent {
  /**
   * Constructor
   */
  constructor() {}

  public getRouterOutletState(outlet) {
    if (outlet) return outlet.isActivated ? outlet.activatedRoute : '';
  }
}
