import {NgModule} from '@angular/core';
import {
  Routes,
  RouterModule,
  Router,
  Scroll,
  RouterEvent,
} from '@angular/router';
import {QuicklinkStrategy, QuicklinkModule} from 'ngx-quicklink';
import {ViewportScroller} from '@angular/common';
import {filter} from 'rxjs/operators';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('@ecommerce/ui-auth/fuse-auth').then((m) => m.FuseUiAuthModule),
  },
  {
    path: 'sample',
    loadChildren: () =>
      import('@ecommerce/ui-components/home/home.module').then(
        (m) => m.HomeModule
      ),
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import(
        '@ecommerce/ui-features/home/home/home.module'
      ).then( m => m.HomeModule )

  },
  {
    path: 'contact',
    loadChildren: () =>
      import(
        '@ecommerce/ui-features/home/contact/contact.module'
      ).then( m => m.ContactModule )

  },
  {
    path: 'pricing',
    loadChildren: () =>
      import(
        '@ecommerce/ui-features/home/pricing/pricing.module'
      ).then( m => m.PricingModule )

  },
  {
    path: 'about-us',
    loadChildren: () =>
      import(
        '@ecommerce/ui-features/home/about-us/about-us.module'
      ).then( m => m.AboutUsModule )

  },
  {
    path: '404',
    loadChildren: () =>
      import(
        '@ecommerce/ui-features/error-pages/not-found404/not-found404.module'
      ).then((m) => m.NotFound404Module),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('@ecommerce/ui-features/home/home/home.module').then(
        (m) => m.HomeModule
      ),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('@ecommerce/ui-features/home/contact/contact.module').then(
        (m) => m.ContactModule
      ),
  },
  {
    path: 'articles/:slug',
    pathMatch: 'full',
    loadChildren: () =>
      import('@ecommerce/ui-features/page/page.module').then(
        (m) => m.PageViewModule
      ),
  },
  {
    path: ':slug',
    loadChildren: () =>
      import('@ecommerce/ui-features/page/page.module').then(
        (m) => m.PageViewModule
      ),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes, {
    // App Default routing Strategy
    // routes will load once appeared on screen.
    preloadingStrategy: QuicklinkStrategy,
    urlUpdateStrategy: 'eager',
    // Enables Scroll to previous position where left once the route is reloaded
    scrollPositionRestoration: 'top',
    // initialNavigation: 'enabled',
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events
      .pipe(filter((e) => e instanceof Scroll))
      .subscribe((e: any) => {
        if (e.position) {
          // backward navigation
          viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          // anchor navigation
          viewportScroller.scrollToAnchor(e.anchor);
        } else {
          // forward navigation
          viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }
}
