<div class="formly-mat-image-upload-wrapper">
  <image-uploader [resizeToWidth]="to.resizeToWidth || 300"
                  [resizeToHeight]="to.resizeToHeight || 300"
                  [cropperMinHeight]="to.cropperMinHeight || 300"
                  [cropperMinWidth]="to.cropperMinWidth || 300"
                  [cropTheImage]="to.cropTheImage"
                  [maintainAspectRatio]="to.maintainAspectRatio"
                  [image]="formControl?.value"
                  [editable]="to.editable"
                  [showOverlay]="to.showOverlay"
                  [aspectRatio]="to.aspectRatio"
                  (fileSaved)="onChange($event)">
    <div class="image-container">
      <img [src]="
        formControl?.value?.url || 'assets/images/backgrounds/no-image.png'"
        [width]="to.resizeToWidth || 300"
        [height]="to.resizeToHeight || 300"
        alt=""
        [class]="to.imageClassName || 'image'" />
    </div>
  </image-uploader>
  <div class="h3 text-center"
       *ngIf="to.label">{{ to.label }}</div>
</div>
