import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { FLOATITEM } from '../modals/add-widget-modal/add-widget-modal.component';

@Component( {
  selector: 'comp-float-bar',
  templateUrl: './float-bar.component.html',
  styleUrls: [ './float-bar.component.scss' ],
  encapsulation: ViewEncapsulation.None
} )
export class FloatBarComponent implements OnInit {
  @Input() items = [];
  @Output() selectedItem: EventEmitter<FLOATITEM> = new EventEmitter();
  constructor () { }

  ngOnInit (): void {
  }
  selectItem ( item: FLOATITEM ) {
    this.selectedItem.emit( item );
  }
}
