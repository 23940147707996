<fuse-layout-component
  [navigation]="navigation"
  [navigationEnglish]="navigationEnglish"
  [navigationTurkish]="navigationTurkish"
></fuse-layout-component>

<!-- <div class="loading-container" *ngIf="loadingRoute || globalLoading">
  <div class="spinner">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</div> -->

<button
  class="scroll-totop-button"
  mat-fab
  mat-icon-button
  aria-label="Press here to goto top"
  color="accent"
  (click)="scrollToTop()"
>
  <i class="icon icon-arrow-up"></i>
</button>
