import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {
  UiWidgetsApiControllerClient,
  UiWidgetsVm,
} from '@ecommerce/ui-services/client-api';
import {BehaviorSubject, forkJoin, merge, Observable, Subject} from 'rxjs';
import {
  take,
  tap,
  filter,
  map,
  takeUntil,
  debounceTime,
  switchMap,
  catchError,
} from 'rxjs/operators';
import {floatItems, WIDGETTEMPLATE} from '../../widgets/sample-widgets';

export interface FLOATITEM {
  id: string;
  icon: string;
  name: string;
}
@Component({
  selector: 'comp-add-widget-modal',
  templateUrl: './add-widget-modal.component.html',
  styleUrls: ['./add-widget-modal.component.scss'],
})
export class AddWidgetModalComponent implements OnInit, OnDestroy {
  floatItems: FLOATITEM[] = [];
  widgets$: Observable<UiWidgetsVm[]>;
  page$: BehaviorSubject<number> = new BehaviorSubject(0);
  get page() {
    return this.page$.value;
  }
  searchText$: BehaviorSubject<string> = new BehaviorSubject('');
  filter$: BehaviorSubject<any> = new BehaviorSubject({});
  get filter() {
    return this.filter$.value;
  }
  limit = 5;
  docCount = 0;
  loading = true;
  loading1 = false;
  value = '';

  private _data: UiWidgetsVm[] = [];

  unSubAll: Subject<any> = new Subject();

  constructor(
    private readonly _uiWidgetsService: UiWidgetsApiControllerClient,
    public dialogRef: MatDialogRef<AddWidgetModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WIDGETTEMPLATE[]
  ) {
    this.floatItems = floatItems;
  }
  ngOnInit(): void {
    this.widgets$ = this._getWidgets(this.limit);
    this.searchTextObserver$().subscribe();
  }
  ngOnDestroy(): void {
    this.unSubAll.next();
    this.unSubAll.complete();
  }
  getItems(item: FLOATITEM) {
    if ( this.loading==false){
      this.loading1 = true
    }
    this.value = '';
    let _filter;
    if (item.id !== 'all') {
      _filter =  item.id;
    } else {
      _filter = '';
    }
    // this.filter$.next(_filter);
    this.searchText$.next( _filter );
    this._reset();
  }

  searchTextObserver$() {
    return this.searchText$.pipe(
      debounceTime(500),
      takeUntil(this.unSubAll),
      tap((v) =>
        this.filter$.next({
          $or: [
            {
              name: {$regex: v, $options: 'i'},
            },
            {
              type: {$regex: v, $options: 'i'},
            },
            {
              tags: { $regex: v, $options: 'i' },
            },
          ],
        })
      ),
      tap((v) => this._reset())
    );
  }

  searchText(v) {
    
    if ( this.loading == false ) {
      this.loading1 = true
    }
    if (v && v.length < 3) return;
    this.searchText$.next(v);
  }

  addWidget(widgetTemplate: UiWidgetsVm) {
    this.dialogRef.close(widgetTemplate);
  }
  close(): void {
    this.dialogRef.close();
    this.unSubAll.next();
    this.unSubAll.complete();
  }
  loadMore(event: Event) {
    this.page$.next(this.page$.value + 1);
  }
  limitReached(): boolean {
    return this.docCount <= this.page$.value * this.limit + this.limit;
  }

  private _reset() {
    this.page$.next(0);
    this._data = [];
  }
  private _getWidgets(limit: number) {
    // merge map with search text
    return merge(
      this.page$.asObservable()
      // this.filter$.asObservable(),
      // this.searchTextObserver$()
    ).pipe(
      takeUntil(this.unSubAll),
      switchMap((v) =>
        forkJoin([
          this._uiWidgetsService.getUiWidgetsCount(JSON.stringify(this.filter)),
          this._uiWidgetsService.findAll(
            this.page * limit,
            limit,
            JSON.stringify({}),
            JSON.stringify(this.returnFilter())
          ),
        ])
      ),
      tap(([count]) => (this.docCount = count as number)),
      filter(([count]) => count > this.page * limit),
      tap( ( __ ) => ( this.loading = false, this.loading1 = false )),
      map(([count, widgets]: [number, UiWidgetsVm[]]) => {
        this._data.push(...widgets);
        return [...this._data];
      })
    );
  }

  private returnFilter() {
    if ( !!this.filter?.$or?.[ 0 ].name?.$regex || !!this.filter?.$or?.[ 1 ].type?.$regex ) {
      return this.filter;
    }
    return {};
  }
}
