import { NgModule } from '@angular/core';

import { RendererComponent } from './renderer.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  exports: [RendererComponent],
  declarations: [RendererComponent],
  providers: [],
})
export class RendererModule {}
