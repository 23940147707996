<div
  *ngFor="let field of field.fieldGroup; let i = index;"
  class="dynamic-field-container"
>
  <formly-field class="fields-wrapper" [field]="field"></formly-field>
  <div class="remove-btn">
    <button mat-stroked-button color="warn" (click)="remove(i)">remove</button>
  </div>
</div>
<div style="margin: 30px 0">
  <button
    mat-stroked-button
    color="accent"
    type="button"
    (click)="add()"
  >
    {{to.addText}}
  </button>
</div>
