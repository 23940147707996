import {isPlatformServer} from '@angular/common';
import {
  Directive,
  ElementRef,
  OnInit,
  Input,
  Renderer2,
  OnDestroy,
  Inject,
  PLATFORM_ID,
} from '@angular/core';

@Directive({
  selector: '[wow]',
})
export class WowDirective implements OnInit, OnDestroy {
  @Input() animationClass: string;
  @Input() enable: boolean = false;
  @Input() repeat: boolean = false;
  @Input() threshold = 0.5;
  private observer: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private _platformId: string
  ) {}

  ngOnInit() {
    if (this.enable && !isPlatformServer(this._platformId)) {
      this.renderer.addClass(this.el.nativeElement, 'hidden');
      this.render();
    }
  }
  render() {
    this.observer = new IntersectionObserver(
      (entry) => {
        const intersectingElement = entry[0];
        if (intersectingElement && intersectingElement.isIntersecting) {
          this.renderer.removeClass(this.el.nativeElement, 'hidden');
          if (!this.repeat) {
            this.observer.unobserve(this.el.nativeElement);
          }
          this.animationClass.split(' ').forEach((className) => {
            this.renderer.addClass(this.el.nativeElement, className);
          });
        } else {
          this.animationClass.split(' ').forEach((className) => {
            this.renderer.removeClass(this.el.nativeElement, className);
          });
        }
      },
      {
        threshold: this.threshold,
      }
    );
    this.observer.observe(this.el.nativeElement);
  }
  ngOnDestroy() {
    this.observer && this.observer.disconnect();
  }
}
