import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  PLATFORM_ID,
} from '@angular/core';

import {navigation} from './navigation/navigation';
import {locale as navigationEnglish} from './navigation/i18n/en';
import {locale as navigationTurkish} from './navigation/i18n/tr';
import {fuseConfig} from './fuse-config';

import {
  Router,
  NavigationStart,
  NavigationError,
  NavigationEnd,
} from '@angular/router';
import {takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {isPlatformServer} from '@angular/common';
import {LoadingService} from '@ecommerce/ui-services/loading/loading.service';
import {AuthGuardMessages} from '@ecommerce/ui-services/guards/auth.guards';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'comp-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig = fuseConfig;
  navigation = navigation;

  navigationEnglish = navigationEnglish;
  navigationTurkish = navigationTurkish;

  globalLoading = false;
  loadingRoute = false;
  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private readonly _router: Router,
    private readonly loadingService: LoadingService,
    private readonly _snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private _platformId: string
  ) {}

  // Privates

  // Public
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  ngOnInit() {
    this._router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        tap((event) => this.checkRouterEvents(event))
      )
      .subscribe();

    this.loadingService.loadingObserver.subscribe(
      (loading) => (this.globalLoading = loading)
    );
  }

  checkRouterEvents(event) {
    if (event instanceof NavigationStart) {
      this.loadingRoute = true;
    } else if (event instanceof NavigationError) {
      this.loadingRoute = false;
      if (event && event.error && event.error.message) {
        switch (event.error.message) {
          case AuthGuardMessages.NOT_AUTHORIZED:
          case AuthGuardMessages.ADMIN_REQUIRED:
          case AuthGuardMessages.BLOGGER_REQUIRED:
          case AuthGuardMessages.CM_REQUIRED:
          case AuthGuardMessages.CSR_REQUIRED:
          case AuthGuardMessages.ENG_REQUIRED:
            this._snackBar.open(event.error.message);
            this._router.navigate(['/auth/login']);
            break;
          default:
            this._router.navigate(['/404']);
            break;
        }
      }
    } else if (event instanceof NavigationEnd) {
      this.loadingRoute = false;
      this.loadingService.loading = false;

      this.scrollToTop();

      // TODO: Implement Google Tag Manager to implement Google Analytics
      // this._gaService.emitPageEvent(event);

      // this.setNavigation(event.url || location.pathname);
    }
  }
  scrollToTop() {
    if (!isPlatformServer(this._platformId))
      window.scrollTo({top: 0, behavior: 'smooth'});
  }
}
